import Dialog from '../../../components/dialog/Dialog'
import React from 'react'
import { Box, Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'

interface AcceptingProps {
	errors: object
}

type PopInHelpActivationProps = AcceptingProps & DialogCommonProps

const PopInErrorVerify: React.FC<PopInHelpActivationProps> = ({
	onCloseDialog,
	open,
	errors
}) => {
	return (
		<Dialog onCloseDialog={onCloseDialog} open={open}>
			<>
				<Grid
					sx={{
						padding: '15px',
						textAlign: 'center'
					}}
				>
					<Box
						component="h1"
						sx={{
							color: 'texts.lightRed'
						}}
					>
						Erreur
					</Box>
					{Object.keys(errors).map((key) => (
						<Grid
							sx={{
								color: 'texts.lightRed',
								backgroundColor: 'backgrounds.pink',
								padding: '15px',
								borderLeft: '3px solid',
								marginBottom: '20px'
							}}
						>
							<p>Le montant pour les cartes de reference</p>
							<h4>{key}</h4>
							<p>est incorrect : {errors[key as keyof typeof errors]}</p>
						</Grid>
					))}
				</Grid>
				<Grid
					container
					justifyContent="center"
					sx={{
						backgroundColor: 'backgrounds.blue',
						padding: '15px'
					}}
				>
					<Button bgcolor="buttonRed" color="white" onClick={onCloseDialog}>
						Fermer
					</Button>
				</Grid>
			</>
		</Dialog>
	)
}

export default PopInErrorVerify
