import React, { memo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { linksEnum } from './enums/linksEnum'
import Login from '../modules/login/Login'
import Enseignes from '../modules/enseignes/Enseignes'
import Saisie from '../modules/saisie/Saisie'
import Historique from '../modules/historique/Historique'
import Utilisateurs from '../modules/utilisateurs/Utilisateurs'
import PointsDeVente from '../modules/pointsDeVente/pointsDeVente'
import Reinitialisation from '../modules/reinitialisation/Reinitialisation'
import FAQ from '../modules/faq/FAQ'

const Root = () => (
	<Routes>
		<Route path={linksEnum.login} element={<Login />} />
		<Route path={linksEnum.enseignes} element={<Enseignes />} />
		<Route path={linksEnum.saisie} element={<Saisie />} />
		<Route path={linksEnum.pointsDeVentes} element={<PointsDeVente />} />
		<Route path={linksEnum.historique} element={<Historique />} />
		<Route path={linksEnum.utilisateurs} element={<Utilisateurs />} />
		<Route path={linksEnum.reinitpass} element={<Reinitialisation />} />
		<Route path={linksEnum.faq} element={<FAQ />} />
		<Route path="*" element={<Navigate to={linksEnum.login} />} />
	</Routes>
)

export default memo(Root)
