import React from 'react'
import { Box, Grid } from '@mui/material'
import { Field, Form } from 'react-final-form'
import { unflatten } from 'flat'
import { categoriesFormFieldEnum } from '../../../enums/popinCategoryEnum'
import { CategoryModel } from '../../../types/FAQModel'
import { FormErrors } from '../../../../../components/fields/types/fieldsModel'
import FormTextField from '../../../../../components/fields/FormTextField'
import Loader from '../../../../../components/loader/Loader'
import { buttonTypesEnum } from '../../../../../components/button/enums/buttonTypesEnum'
import Button from '../../../../../components/button/Button'

interface AcceptingProps {
	onSubmit: (values: CategoryModel) => Promise<any>
	loading: boolean
	onCancel: () => void
	initialValues?: CategoryModel
}

type FAQFormComponentProps = AcceptingProps

const FAQFormComponent: React.FC<FAQFormComponentProps> = ({
	onSubmit,
	loading,
	onCancel,
	initialValues
}) => {
	const validate = (values: CategoryModel) => {
		const errors: FormErrors<CategoryModel> = {}

		if (!values.label || values.label.length === 0) {
			errors.label = 'Le libellé est requis*'
		}

		return unflatten<typeof errors, CategoryModel>(errors)
	}

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={initialValues}
			render={({ handleSubmit, submitErrors }) => (
				<form onSubmit={handleSubmit} style={{ width: '100%' }}>
					<Grid sx={{ p: '0 24px 20px' }} container item xs={12}>
						<Field
							name={categoriesFormFieldEnum.label}
							type="text"
							label="Libellé"
							component={FormTextField}
							sx={{
								marginBottom: '10px'
							}}
						/>

						{submitErrors && (
							<Box
								component="p"
								sx={{
									whiteSpace: 'break-spaces',
									color: 'texts.lightRed',
									fontSize: '12px'
								}}
							>
								{submitErrors}
							</Box>
						)}
					</Grid>

					<Grid
						sx={{
							p: '15px',
							bgcolor: 'backgrounds.blue'
						}}
						container
						item
						xs={12}
						justifyContent="center"
					>
						<Grid container item xs={6} justifyContent="left">
							<Button
								bgcolor="buttonRed"
								color="white"
								type={buttonTypesEnum.reset}
								onClick={onCancel}
							>
								ANNULER
							</Button>
						</Grid>
						<Grid container item xs={6} justifyContent="right">
							{loading ? (
								<Loader size={40} />
							) : (
								<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
									VALIDER
								</Button>
							)}
						</Grid>
					</Grid>
				</form>
			)}
		/>
	)
}

export default FAQFormComponent
