import Dialog from '../../../components/dialog/Dialog'
import React from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'

type PopInHelpActivationProps = DialogCommonProps

const PopInHelpActivation: React.FC<PopInHelpActivationProps> = ({
	onCloseDialog,
	open
}) => {
	return (
		<Dialog onCloseDialog={onCloseDialog} open={open}>
			<Grid
				sx={{
					padding: '15px'
				}}
			>
				<img src="/img/num_logistique.png" width="100%" />
				<Button bgcolor="buttonRed" color="white" onClick={onCloseDialog}>
					Fermer
				</Button>
			</Grid>
		</Dialog>
	)
}

export default PopInHelpActivation
