export enum popinFAQFormFieldEnum {
	id = 'id',
	question = 'question',
	answer = 'answer',
	category = 'category',
	position = 'position',
	relatedRole = 'relatedRole'
}

export const getKeyFromPopinFAQFormFieldValue = (value: popinFAQFormFieldEnum) =>
	Object.keys(popinFAQFormFieldEnum).find(
		(popinFAQFormField) =>
			popinFAQFormFieldEnum[popinFAQFormField as keyof typeof popinFAQFormFieldEnum] ===
			value
	) ?? ''
