export enum enseignesTableLabelEnum {
	nom = 'Nom',
	logo = 'Logo',
	pointsDeVentes = 'Points de ventes',
	nombreDeRefsProduit = 'Nombre de références produit'
}

export enum enseignesFieldsEnum {
	Enseigne_active = 'Enseigne_active',
	Ezp_id = 'Ezp_id',
	Ezp_mdp = 'Ezp_mdp',
	Id_enseignes = 'Id_enseignes',
	Logo = 'Logo',
	Montant_max = 'Montant_max',
	Montant_min = 'Montant_min',
	Nom_enseigne = 'Nom_enseigne',
	Nombre_max_cartes = 'Nombre_max_cartes',
	Nombre_saisie = 'Nombre_saisie',
	nombreDePointsDeVentes = 'nombreDePointsDeVentes',
	nombreDeReferencesProduit = 'nombreDeReferencesProduit',
	file = 'file',
	reapprovisionnement = 'reapprovisionnement'
}
