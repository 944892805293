import React from 'react'
import { TableModaleButtonType } from './types/buttonModel'
import { styled } from '@mui/material'
import ButtonUnstyled from '@mui/base/ButtonUnstyled'

const CustomButton = styled('button')`
	cursor: pointer;
	border: none;
	padding: 1px 5px;
	font-size: 14px;
	line-height: 1.5;
	vertical-align: middle;
	border: 1px solid transparent;
	text-align: center;
	margin: 0 5px;
`

const TableModaleButton: React.FC<TableModaleButtonType> = ({
	children,
	bgcolor = 'darkBlue',
	color = 'white',
	onClick,
	icon
}) => (
	<ButtonUnstyled
		sx={{
			bgcolor: `backgrounds.${bgcolor}`,
			color: `texts.${color}`,
			'& > svg': {
				verticalAlign: 'middle',
				fontSize: '15px'
			}
		}}
		component={CustomButton}
		onClick={onClick}
	>
		{icon}
		{children}
	</ButtonUnstyled>
)

export default TableModaleButton
