import axios from 'axios'
import { statutReponseEnum } from '../../../containers/enums/statutReponseEnum'
import { carteInactiveModel } from '../types/carteInactiveModel'

export const postDemandesFinalApi = (
	lots: {
		pdv: number
		commentaire: string
		numeroDeLaPremiereCarte: string | undefined
		numeroDeLaDerniereCarte: string | undefined
		prix: number | undefined
	}[]
): Promise<{
	pdv: number
	messages: string
	statut: statutReponseEnum
	lastInsertDemandesIds: string[]
	cartesNonValide: carteInactiveModel[] | undefined
}> =>
	axios
		.post('/api/demandes/', lots)
		.then((res) => res.data)
		.catch((error) => error.response.data)
