import React, { useContext, useEffect, useState } from 'react'
import { Button, Snackbar as MuiSnackbar } from '@mui/material'
import { AppContext } from '../../containers/context/AppContext'
import { INITIAL_SNACKBAR, SnackBarModel } from './types/snackBarModel'
import { colorSnackBarEnum } from '../../containers/enums/colorSnackBarEnum'

interface ModelSnackBarData {
	id: string
	message: string
	open: boolean
	color?: string
	icon?: colorSnackBarEnum
}

interface StoreProps {
	snackBarData: ModelSnackBarData
}

type SnackBarProps = StoreProps
const SnackBar: React.FC<SnackBarProps> = () => {
	const vertical = 'top'
	const horizontal = 'center'
	const [snackBarData, setsnackBarData] = useState<SnackBarModel>(INITIAL_SNACKBAR)
	const { snackBar, setSnackBar } = useContext(AppContext)

	useEffect(() => {
		setsnackBarData(snackBar)
	}, [snackBar])

	const handleClose = (event: any, reason: any) => {
		setSnackBar(INITIAL_SNACKBAR)
	}

	return (
		<>
			<MuiSnackbar
				anchorOrigin={{ vertical, horizontal }}
				key={snackBarData.id}
				open={snackBarData.open}
				onClose={handleClose}
				className={snackBarData.icon}
				ContentProps={{
					'aria-describedby': snackBarData.id
				}}
				sx={{
					backgroundColor: snackBarData.color ?? 'backgrounds.green'
				}}
				message={
					<div>
						<Button
							className="toast-close-button ng-scope"
							onClick={() => {
								setSnackBar(INITIAL_SNACKBAR)
							}}
						>
							×
						</Button>
						<div
							id={snackBarData.id}
							style={{
								marginLeft: '20px'
							}}
						>
							<span dangerouslySetInnerHTML={{ __html: snackBarData.message }} />
						</div>
					</div>
				}
			/>
		</>
	)
}

export default SnackBar
