import React, { useState } from 'react'
import { Box, Grid, Tab, Tabs, Theme } from '@mui/material'
import LoginForm from './components/LoginForm'
import RegistrationForm from './components/RegistrationForm'
import { TabPanelModel } from './types/loginModel'
import ForgotPassword from './components/ForgotPassword'

const Login = () => {
	// State permettant de définir si l'utilisateur est sur l'onglet de connexion (0) ou d'inscription (1)
	const [isLogin, setIsLogin] = useState<number>(0)
	const [hasForgotPassword, setHasForgotPassword] = useState(false)

	// Props en commun sur les tabs
	const tabProps = (index: number) => ({
		sx: [
			(theme: Theme) => ({
				color: `${theme.palette.texts.button} !important`,

				'&:hover': {
					color: `${theme.palette.texts.white} !important`
				}
			}),
			{
				textTransform: 'inherit',
				p: '10px',
				fontSize: 14,
				fontWeight: 400,
				minHeight: 40,
				bgcolor: index === isLogin ? 'backgrounds.darkGrey' : 'rgba(53, 66, 82, 0.7)',
				'&:hover': {
					bgcolor: index === isLogin ? '' : 'backgrounds.grey'
				}
			}
		],
		id: `login-tab-${index}`,
		'aria-controls': `login-tab-${index}`
	})

	// Container des onglets de connexion et inscription
	const TabPanel: React.FC<TabPanelModel> = ({ index, value, children }) => (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`tab-container-${index}`}
			aria-labelledby={`tab-container-${index}`}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	)

	return (
		<Grid
			sx={{
				backgroundColor: 'backgrounds.darkBlue',
				height: '100vh',
				p: '60px 10px'
			}}
			container
			item
			xs={12}
			justifyContent="center"
		>
			{/* Container de la partie centrale du formulaire */}
			<Box
				sx={{
					width: {
						xs: '100%',
						sm: 420
					},
					textAlign: 'center'
				}}
			>
				<img
					src="/img/logo/spirit1.png"
					alt="Logo Spirit of Cadeau"
					width="200"
					height="104"
				/>

				<Grid
					sx={{
						bgcolor: 'backgrounds.blue',
						mt: 5
					}}
					container
					item
					xs={12}
				>
					<Grid container item xs={12}>
						{hasForgotPassword ? (
							<ForgotPassword setHasForgotPassword={setHasForgotPassword} />
						) : (
							<>
								<Tabs
									sx={{
										width: '100%',
										p: '15px',
										minHeight: 40
									}}
									value={isLogin}
									onChange={() => setIsLogin((prevState) => (prevState === 0 ? 1 : 0))}
									variant="fullWidth"
									TabIndicatorProps={{
										style: {
											height: 3
										}
									}}
								>
									<Tab label="Déjà membre" {...tabProps(0)} />
									<Tab label="1ère connexion ?" {...tabProps(1)} />
								</Tabs>

								<TabPanel value={isLogin} index={0}>
									<LoginForm setHasForgotPassword={setHasForgotPassword} />
								</TabPanel>

								<TabPanel value={isLogin} index={1}>
									<RegistrationForm />
								</TabPanel>
							</>
						)}
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
}

export default Login
