import React from 'react'
import MuiDialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { SxProps } from '@mui/material'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	title?: string
	fullScreen?: boolean
	maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined
	withPadding?: boolean
	onSubmit?: () => void
	sx?: SxProps
	backgroundColor?: string
}

type DialogProps = AcceptingProps

const Dialog: React.FC<DialogProps> = ({
	onCloseDialog,
	open,
	children,
	title,
	fullScreen = false,
	maxWidth = 'sm',
	withPadding = true,
	onSubmit = () => {},
	backgroundColor = '#000',
	sx
}) => {
	const handleClose = () => {
		onCloseDialog()
	}
	return (
		<MuiDialog
			open={open}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth={maxWidth}
			sx={{
				minWidth: '600px',
				'& > .MuiBackdrop-root': {
					backgroundColor: { backgroundColor },
					opacity: '0.5 !important'
				}
			}}
		>
			{title && (
				<>
					<DialogTitle sx={{ p: '0px 24px !important' }}>{title}</DialogTitle>
					<hr />
				</>
			)}
			<DialogContent sx={sx}>{children}</DialogContent>
		</MuiDialog>
	)
}

export default Dialog
