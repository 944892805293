import React, { useContext, useState } from 'react'
import Dialog from '../../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../../components/dialog/types/dialogModel'
import { Box, Grid } from '@mui/material'
import { categoriesContentToDisplayEnum } from '../../enums/popinCategoryEnum'
import ListCategories from './popinCategoryComponents/ListCategories'
import CategoryFormComponent from './popinCategoryComponents/CategoryFormComponent'
import { CategoryModel } from '../../types/FAQModel'
import { postCategory, putCategory } from '../../api/FAQAdminAPI'
import { FAQContext } from '../../context/FAQContext'
import {
	ContentWithCategoryModel,
	INITIAL_CONTENT_WITH_CATEGORY
} from '../../types/FAQAdminModel'
import CategorySuppression from './popinCategoryComponents/CategorySuppression'

const PopinAddQuestion: React.FC<DialogCommonProps> = ({ onCloseDialog, open }) => {
	const [contentToDisplay, setContentToDisplay] = useState<ContentWithCategoryModel>(
		INITIAL_CONTENT_WITH_CATEGORY
	)
	const [loading, setLoading] = useState(false)
	const { categories, setCategories } = useContext(FAQContext)

	const onSubmit = (values: CategoryModel) => {
		setLoading(true)

		let categoryFetch
		// Selon le mode d'affichage, on envoie des données ou on modifie la catégorie selon son id
		if (contentToDisplay.contentToDisplay === categoriesContentToDisplayEnum.addCategory) {
			categoryFetch = postCategory(values)
		} else {
			categoryFetch = putCategory(values, values.id)
		}

		return categoryFetch
			.then((response) => {
				// Si on a ajouté une catégorie, il faut la push dans le tableau des catégories, sinon, il faut modifier celle déjà présente dans la liste
				setCategories((prevCategories) => {
					if (
						contentToDisplay.contentToDisplay === categoriesContentToDisplayEnum.addCategory
					) {
						return [...prevCategories, response.data]
					}

					// On récupère l'index de la catégorie dans la liste
					const index = prevCategories.findIndex((category) => category.id === values.id)

					// On remplace l'ancienne catégorie par la nouvelle
					return [
						...prevCategories.slice(0, index),
						response.data,
						...prevCategories.slice(index + 1)
					]
				})

				// On affiche la liste des catégories mise à jour
				setContentToDisplay(INITIAL_CONTENT_WITH_CATEGORY)
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages

					if (typeof errorData === 'object') {
						return Object.values(errorData)
							.map((errorValue) => `${errorValue} \n`)
							.join('')
					}
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<Dialog onCloseDialog={onCloseDialog} open={open} title="Gérer les catégories">
			{contentToDisplay.contentToDisplay ===
				categoriesContentToDisplayEnum.listCategories && (
				<Grid sx={{ p: '20px 24px' }} container item xs={12}>
					<ListCategories
						categories={categories}
						onOpenAdd={() =>
							setContentToDisplay({
								contentToDisplay: categoriesContentToDisplayEnum.addCategory
							})
						}
						onOpenEdit={(category) =>
							setContentToDisplay({
								contentToDisplay: categoriesContentToDisplayEnum.editCategory,
								category
							})
						}
						onOpenDelete={(category) =>
							setContentToDisplay({
								contentToDisplay: categoriesContentToDisplayEnum.deleteCategory,
								category
							})
						}
					/>
				</Grid>
			)}
			{(contentToDisplay.contentToDisplay === categoriesContentToDisplayEnum.addCategory ||
				contentToDisplay.contentToDisplay ===
					categoriesContentToDisplayEnum.editCategory) && (
				<>
					<Grid container item xs={12}>
						<Grid item xs={12} sx={{ p: '20px 24px 0' }}>
							<Box
								component="h1"
								sx={{
									mt: 0,
									color: 'texts.grey',
									fontSize: '20px'
								}}
							>
								{contentToDisplay.contentToDisplay ===
								categoriesContentToDisplayEnum.addCategory ? (
										<>Ajout d'une catégorie</>
									) : (
										<>Modification de la catégorie</>
									)}
							</Box>
						</Grid>

						<CategoryFormComponent
							onSubmit={onSubmit}
							initialValues={contentToDisplay.category}
							onCancel={() => setContentToDisplay(INITIAL_CONTENT_WITH_CATEGORY)}
							loading={loading}
						/>
					</Grid>
				</>
			)}

			{contentToDisplay.contentToDisplay === categoriesContentToDisplayEnum.deleteCategory &&
				contentToDisplay.category && (
				<CategorySuppression
					category={contentToDisplay.category}
					onCancel={() => setContentToDisplay(INITIAL_CONTENT_WITH_CATEGORY)}
				/>
			)}
		</Dialog>
	)
}

export default PopinAddQuestion
