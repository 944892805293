import { sessionEnum } from '../../../containers/enums/sessionEnum'
import { CategoryModel, FAQModel, INITIAL_FAQ } from './FAQModel'
import { categoriesContentToDisplayEnum } from '../enums/popinCategoryEnum'

export interface FAQAdminModel {
	profileType?: sessionEnum
	questions: FAQModel[]
}

export interface PopinWithElementModel {
	open: boolean
	question: FAQModel
}

export const INITIAL_POPIN_WITH_ELEMENT: PopinWithElementModel = {
	open: false,
	question: INITIAL_FAQ
}

export interface ContentWithCategoryModel {
	contentToDisplay: categoriesContentToDisplayEnum
	category?: CategoryModel
}

export const INITIAL_CONTENT_WITH_CATEGORY: ContentWithCategoryModel = {
	contentToDisplay: categoriesContentToDisplayEnum.listCategories
}
