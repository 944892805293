import Dialog from '../../../components/dialog/Dialog'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Box, Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import Table from '../../../components/table/Table'
import { lotSyntheseLabelEnum } from '../enums/lotSyntheseEnum'
import { FormLotsModel, LotModel } from '../types/lotModel'
import { AppContext } from '../../../containers/context/AppContext'
import { useIntl } from 'react-intl'
import { formLotsEnum } from '../enums/lotEnum'
import { postDemandesVerifyApi } from '../api/postDemandesVerifyApi'
import { statutReponseEnum } from '../../../containers/enums/statutReponseEnum'
import { carteInactiveModel } from '../types/carteInactiveModel'
import { carteInactiveLabelEnum } from '../enums/carteInactiveEnum'
import { postDemandesFinalApi } from '../api/postDemandesFinalApi'
import { useNavigate } from 'react-router-dom'
import { linksEnum } from '../../../containers/enums/linksEnum'
import { demandeStatusEnum } from '../../historique/enums/demandeStatusEnum'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import { getKeyFromEnumValue } from '../../../utils/utils'

interface AcceptingProps {
	values: FormLotsModel
}

type PopInHelpActivationProps = AcceptingProps & DialogCommonProps

const PopInVerify: React.FC<PopInHelpActivationProps> = ({
	onCloseDialog,
	open,
	values
}) => {
	const { session } = useContext(AppContext)
	const intl = useIntl()
	const navigate = useNavigate()
	const [fetchVerify, setFetchVerify] = useState<boolean>(true)
	const [messageVerify, setMessageVerify] = useState<string>('')
	const [demandesFinal, setDemandesFinal] = useState<
		| {
				messages: string
				statut: statutReponseEnum
				lastInsertDemandesIds: string[]
		  }
		| undefined
	>(undefined)
	const [cartesInactives, setCartesInactives] = useState<carteInactiveModel[] | undefined>(
		undefined
	)

	const cartesInactivesHeads = useMemo(
		() => [
			{
				label: carteInactiveLabelEnum.numeroLogistique,
				id: getKeyFromEnumValue<carteInactiveLabelEnum>(
					carteInactiveLabelEnum,
					carteInactiveLabelEnum.numeroLogistique
				)
			},
			{
				label: carteInactiveLabelEnum.statut,
				id: getKeyFromEnumValue<carteInactiveLabelEnum>(
					carteInactiveLabelEnum,
					carteInactiveLabelEnum.statut
				)
			}
		],
		[]
	)

	const syntheseCartesHeads = useMemo(
		() => [
			{
				label: lotSyntheseLabelEnum.Reference,
				id: getKeyFromEnumValue<lotSyntheseLabelEnum>(
					lotSyntheseLabelEnum,
					lotSyntheseLabelEnum.Reference
				)
			},
			{
				label: lotSyntheseLabelEnum.Numero_logistique_premiere_carte,
				id: getKeyFromEnumValue<lotSyntheseLabelEnum>(
					lotSyntheseLabelEnum,
					lotSyntheseLabelEnum.Numero_logistique_premiere_carte
				)
			},
			{
				label: lotSyntheseLabelEnum.Numero_logistique_derniere_carte,
				id: getKeyFromEnumValue<lotSyntheseLabelEnum>(
					lotSyntheseLabelEnum,
					lotSyntheseLabelEnum.Numero_logistique_derniere_carte
				)
			},
			{
				label: lotSyntheseLabelEnum.Nombre_de_carte,
				id: getKeyFromEnumValue<lotSyntheseLabelEnum>(
					lotSyntheseLabelEnum,
					lotSyntheseLabelEnum.Nombre_de_carte
				)
			},
			{
				label: lotSyntheseLabelEnum.Montant_par_carte,
				id: getKeyFromEnumValue<lotSyntheseLabelEnum>(
					lotSyntheseLabelEnum,
					lotSyntheseLabelEnum.Montant_par_carte
				)
			}
		],
		[]
	)

	const submitActivation = async () => {
		const demandesFinal = await postDemandesFinalApi(
			values.lots.map((lot) => ({
				pdv: values.pdv,
				commentaire: values.commentaire,
				numeroDeLaPremiereCarte: lot.Numero_logistique_premiere_carte,
				numeroDeLaDerniereCarte: lot.Numero_logistique_derniere_carte,
				prix: lot.Montant_par_carte
			}))
		)
		switch (demandesFinal.statut) {
			case statutReponseEnum.error:
				setCartesInactives(demandesFinal.cartesNonValide)
				break
			case statutReponseEnum.ok:
			default:
				break
		}
		setDemandesFinal(demandesFinal)
		setMessageVerify(demandesFinal.messages)
	}

	useEffect(() => {
		setFetchVerify(true)
		setMessageVerify('')
		setCartesInactives(undefined)

		if (open) {
			const fetchDemandesVerify = async () => {
				// Appels /api/demandes/verifier
				return await postDemandesVerifyApi(
					values.lots.map((lot) => ({
						pdv: values.pdv,
						commentaire: values.commentaire,
						numeroDeLaPremiereCarte: lot.Numero_logistique_premiere_carte,
						numeroDeLaDerniereCarte: lot.Numero_logistique_derniere_carte,
						prix: lot.Montant_par_carte
					}))
				)
			}

			fetchDemandesVerify().then((response) => {
				switch (response.statut) {
					case statutReponseEnum.error:
						setCartesInactives(response.cartesNonValide)
						break
					case statutReponseEnum.ok:
					default:
						break
				}
				setMessageVerify(response.messages)
				setFetchVerify(false)
			})
		}
	}, [open])

	return (
		<>
			<Dialog onCloseDialog={onCloseDialog} open={open} maxWidth="md">
				{!fetchVerify && (
					<>
						{cartesInactives && cartesInactives.length !== 0 ? (
							<Grid container>
								<Grid
									container
									sx={{
										padding: '15px'
									}}
								>
									<Grid
										sx={{
											color: 'texts.orange',
											backgroundColor: 'backgrounds.lightOrange',
											padding: '15px',
											borderLeft: '3px solid',
											textAlign: 'center',
											width: '100%'
										}}
									>
										<strong>
											<div dangerouslySetInnerHTML={{ __html: messageVerify }} />
										</strong>
									</Grid>
								</Grid>
								<Table
									headRows={cartesInactivesHeads}
									rows={cartesInactives?.map((carte) => ({
										[getKeyFromEnumValue<carteInactiveLabelEnum>(
											carteInactiveLabelEnum,
											carteInactiveLabelEnum.numeroLogistique
										)]: carte.numeroDeLaCarte,
										[getKeyFromEnumValue<carteInactiveLabelEnum>(
											carteInactiveLabelEnum,
											carteInactiveLabelEnum.statut
										)]: carte.statut ?? demandeStatusEnum.ETAT_CARTE_NON_ENREGISTREE
									}))}
								/>
								<Grid
									container
									justifyContent="center"
									sx={{
										backgroundColor: 'backgrounds.blue',
										padding: '15px'
									}}
								>
									<Button bgcolor="buttonRed" color="white" onClick={onCloseDialog}>
										Corriger
									</Button>
								</Grid>
							</Grid>
						) : (
							<Grid
								container
								sx={{
									color: 'texts.grey'
								}}
							>
								<Grid
									container
									justifyContent="center"
									sx={{
										padding: '15px'
									}}
								>
									{demandesFinal && (
										<Grid
											container
											sx={{
												padding: '15px'
											}}
										>
											<Grid
												sx={{
													color:
														demandesFinal.statut === statutReponseEnum.ok
															? 'success.main'
															: 'texts.orange',
													backgroundColor:
														demandesFinal.statut === statutReponseEnum.ok
															? 'backgrounds.lightBackgroundSuccess'
															: 'backgrounds.lightOrange',
													padding: '15px',
													borderLeft: '3px solid',
													textAlign: 'center',
													width: '100%'
												}}
											>
												<strong>
													<div dangerouslySetInnerHTML={{ __html: demandesFinal.messages }} />
												</strong>
											</Grid>
										</Grid>
									)}
									<Box
										component="h2"
										sx={{
											textAlign: 'center'
										}}
									>
										Synthèse des chargements de cartes
									</Box>
									<Grid container>
										<Grid item xs={6}>
											<p>
												<strong>{session.enseigne}</strong>
											</p>
											<p>
												<strong>{session.point_de_vente}</strong>
											</p>
											<p>
												<strong>Nom :</strong> {session.nom}
											</p>
											<p>
												<strong>Prénom :</strong> {session.prenom}
											</p>
										</Grid>
										<Grid
											item
											xs={6}
											sx={{
												textAlign: 'end'
											}}
										>
											<p>Date : {intl.formatDate(new Date()).replaceAll('/', '-')}</p>
										</Grid>
									</Grid>
								</Grid>

								<Table
									headRows={syntheseCartesHeads}
									rows={values[formLotsEnum.lots].map((lot: LotModel) => ({
										[getKeyFromEnumValue<lotSyntheseLabelEnum>(
											lotSyntheseLabelEnum,
											lotSyntheseLabelEnum.Reference
										)]: lot.Reference,
										[getKeyFromEnumValue<lotSyntheseLabelEnum>(
											lotSyntheseLabelEnum,
											lotSyntheseLabelEnum.Numero_logistique_premiere_carte
										)]: lot.Numero_logistique_premiere_carte,
										[getKeyFromEnumValue<lotSyntheseLabelEnum>(
											lotSyntheseLabelEnum,
											lotSyntheseLabelEnum.Numero_logistique_derniere_carte
										)]: lot.Numero_logistique_derniere_carte,
										[getKeyFromEnumValue<lotSyntheseLabelEnum>(
											lotSyntheseLabelEnum,
											lotSyntheseLabelEnum.Nombre_de_carte
										)]:
											parseInt(lot.Numero_logistique_derniere_carte as string) -
											parseInt(lot.Numero_logistique_premiere_carte as string) +
											1,
										[getKeyFromEnumValue<lotSyntheseLabelEnum>(
											lotSyntheseLabelEnum,
											lotSyntheseLabelEnum.Montant_par_carte
										)]: lot.Montant_par_carte
									}))}
									hasPagination={false}
									referenceColumn={0}
								></Table>

								<Table
									rows={[
										{
											label: lotSyntheseLabelEnum.Nombre_total_de_cartes,
											id: values[formLotsEnum.lots].reduce(
												(resultat: number, lot) =>
													resultat +
													parseInt(lot.Numero_logistique_derniere_carte as string) -
													parseInt(lot.Numero_logistique_premiere_carte as string) +
													1,
												0
											)
										},
										{
											label: lotSyntheseLabelEnum.Montant_total,
											id: values[formLotsEnum.lots].reduce(
												(resultat: number, lot) =>
													resultat +
													(lot.Montant_par_carte ?? 0) *
														(parseInt(lot.Numero_logistique_derniere_carte as string) -
															parseInt(lot.Numero_logistique_premiere_carte as string) +
															1),
												0
											)
										}
									]}
									hasPagination={false}
									rowBackgroundColor="backgrounds.lightYellow"
								></Table>

								{values[formLotsEnum.commentaire] && (
									<Grid
										container
										sx={{
											padding: '15px'
										}}
									>
										<Box
											component="strong"
											sx={{
												textAlign: 'center',
												backgroundColor: '#f9f9f9',
												borderTop: '1px solid #ddd',
												borderBottom: '1px solid #ddd',
												width: '100%',
												padding: '8px'
											}}
										>
											Commentaires
										</Box>
										<p>{values[formLotsEnum.commentaire]}</p>
									</Grid>
								)}

								<Grid
									container
									justifyContent="space-between"
									sx={{
										backgroundColor: 'backgrounds.blue',
										padding: '15px'
									}}
								>
									<Button
										bgcolor="buttonRed"
										color="white"
										onClick={
											demandesFinal && demandesFinal.statut === statutReponseEnum.ok
												? () => navigate(linksEnum.historique)
												: onCloseDialog
										}
									>
										{demandesFinal && demandesFinal.statut === statutReponseEnum.ok
											? 'Fermer'
											: 'Corriger'}
									</Button>
									<Button
										bgcolor="green"
										color="white"
										onClick={
											demandesFinal && demandesFinal.statut === statutReponseEnum.ok
												? () =>
													window.open(
														`/api/demandes/rapport/pdf?id=["${demandesFinal.lastInsertDemandesIds}"]`,
														'_blank'
													)
												: submitActivation
										}
									>
										{demandesFinal && demandesFinal.statut === statutReponseEnum.ok
											? 'Enregistrer au format PDF'
											: 'Valider'}
									</Button>
								</Grid>
							</Grid>
						)}
					</>
				)}
			</Dialog>
		</>
	)
}

export default PopInVerify
