import React, { useState } from 'react'
import Dialog from '../../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../../components/dialog/types/dialogModel'
import { FAQModel } from '../../types/FAQModel'
import { popinFAQFormFieldEnum } from '../../enums/popinAddQuestionEnum'
import { Grid } from '@mui/material'
import Button from '../../../../components/button/Button'
import { buttonTypesEnum } from '../../../../components/button/enums/buttonTypesEnum'
import Loader from '../../../../components/loader/Loader'
import { deleteQuestion } from '../../api/FAQAdminAPI'

interface AcceptingProps {
	question: FAQModel
}

type PopinEditQuestionProps = AcceptingProps & DialogCommonProps

const PopinEditQuestion: React.FC<PopinEditQuestionProps> = ({
	onCloseDialog,
	open,
	question
}) => {
	const [loading, setLoading] = useState(false)

	const onDeleteQuestion = () => {
		setLoading(true)

		return deleteQuestion(question[popinFAQFormFieldEnum.id])
			.then(() => onCloseDialog())
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages

					if (typeof errorData === 'object') {
						return Object.values(errorData)
							.map((errorValue) => `${errorValue} \n`)
							.join('')
					}
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title="Suppression de la question"
			sx={{
				minWidth: 400
			}}
		>
			<Grid sx={{ p: '20px 24px' }} container item xs={12}>
				<p>
					Voulez-vous vraiment supprimer la question{' '}
					<strong>{question[popinFAQFormFieldEnum.question]}</strong> ?
				</p>
			</Grid>

			<Grid
				sx={{
					p: '15px',
					bgcolor: 'backgrounds.buttonRedContainer'
				}}
				container
				item
				xs={12}
				justifyContent="center"
			>
				<Grid container item xs={6} justifyContent="left">
					<Button
						bgcolor="buttonRed"
						color="white"
						type={buttonTypesEnum.reset}
						onClick={onCloseDialog}
					>
						ANNULER
					</Button>
				</Grid>
				<Grid container item xs={6} justifyContent="right">
					{loading ? (
						<Loader size={40} />
					) : (
						<Button
							bgcolor="green"
							color="white"
							type={buttonTypesEnum.button}
							onClick={onDeleteQuestion}
						>
							SUPPRIMER
						</Button>
					)}
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default PopinEditQuestion
