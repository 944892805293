export enum detailsActivationTableHeadEnum {
	numeroLogistique = 'Numéro logistique',
	montant = 'Montant',
	etatDeLaCarte = 'État de la carte',
	raisonAnomalie = 'Raison de l\'anomalie'
}

export const getKeyFromDetailsActivationsTableHead = (
	value: detailsActivationTableHeadEnum
) =>
	Object.keys(detailsActivationTableHeadEnum).find(
		(detailsActivationTableHead) =>
			detailsActivationTableHeadEnum[
				detailsActivationTableHead as keyof typeof detailsActivationTableHeadEnum
			] === value
	) ?? ''
