import { Grid } from '@mui/material'
import React from 'react'
import { Home } from '@mui/icons-material'
import { linksEnumData } from '../../containers/enums/linksEnum'

const Ariane = () => {
	const linksData = Object.values(linksEnumData).filter(
		(values) => values.url === location.pathname
	)
	const getLabelFromPathname = () => (linksData.length > 0 ? linksData[0].label : '')

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				flex: '1 !important'
			}}
		>
			<Grid
				item
				sx={{
					display: 'flex',
					alignItems: 'center',
					padding: '13px',
					color: 'texts.button',
					backgroundColor: 'backgrounds.darkPurple',
					'&:hover': {
						color: 'texts.white'
					}
				}}
			>
				<Home
					sx={{
						marginLeft: 1
					}}
				/>
				Glady
			</Grid>
			<Grid
				item
				sx={{
					display: 'flex',
					alignItems: 'center',
					padding: '13px',
					color: 'texts.green',
					backgroundColor: 'backgrounds.white',
					flexGrow: 1,
					'&:hover': {
						color: 'texts.green'
					}
				}}
			>
				{`> ${getLabelFromPathname()}`}
			</Grid>
		</Grid>
	)
}
export default Ariane
