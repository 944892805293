import axios from 'axios'
import { CategoryModel, FAQModel } from '../types/FAQModel'

// API pour les questions de la FAQ
export const getQuestions = <FAQSpecificModel extends []>(): Promise<FAQSpecificModel> =>
	axios('/api/faq').then((res) => res.data)
export const postQuestion = (data: FAQModel) => axios.post('/api/faq', data)
export const putQuestion = (data: FAQModel, id: number) =>
	axios.put(`/api/faq/${id}`, data)
export const deleteQuestion = (id: number) => axios.delete(`/api/faq/${id}`)

// API pour les catégories
export const getCategories = (): Promise<CategoryModel[]> =>
	axios
		.get('/api/categories')
		.then((res) => res.data)
		.catch((error) => error.response.data.messages)
export const postCategory = (data: CategoryModel) => axios.post('/api/category', data)
export const putCategory = (data: CategoryModel, id: number) =>
	axios.put(`/api/category/${id}`, data)
export const deleteCategory = (id: number) => axios.delete(`/api/category/${id}`)
