import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { FieldRenderProps } from 'react-final-form'
import { Box } from '@mui/material'

interface AcceptingProps {
	label: string
	accept?: string[]
	maxFile?: number
	fileSize: number
}

type FormDropzoneAreaFieldProps = FieldRenderProps<File[]> & AcceptingProps

const FormDropzoneAreaField: React.FC<FormDropzoneAreaFieldProps> = ({
	input,
	meta: { error, touched },
	label,
	accept,
	maxFile = 1,
	fileSize
}) => {
	return (
		<>
			<DropzoneArea
				onChange={(files) => input.onChange(files)}
				dropzoneText={label}
				maxFileSize={fileSize}
				inputProps={{
					name: input.name
				}}
				acceptedFiles={accept}
				filesLimit={maxFile}
				initialFiles={input.value}
			/>

			{touched && error && (
				<Box
					sx={{
						color: 'texts.lightRed',
						fontSize: '12px',
						width: '100%',
						textAlign: 'center'
					}}
				>
					{error}
				</Box>
			)}
		</>
	)
}

export default FormDropzoneAreaField
