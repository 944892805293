import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { Grid } from '@mui/material'
import CustomTable from '../../components/table/Table'
import TableModaleButton from '../../components/button/TableModaleButton'
import { Add, Check, Close, Edit } from '@mui/icons-material'
import { INITIAL_POINT_DE_VENTE, PointDeVenteModel } from './types/pointDeVenteModel'
import { editStatusPDV, fetchPointsdeVente } from './api/pointsDeVenteApi'
import {
	getKeyFromPointDeVenteTableLabelValue,
	pointDeVenteTableLabelEnum
} from './enums/pointsDeVenteEnum'
import { colorSnackBarEnum } from '../../containers/enums/colorSnackBarEnum'
import { AppContext } from '../../containers/context/AppContext'
import PopinPointDeVente from './components/PopinPointDeVente'
import { useNavigate } from 'react-router-dom'
import { linksEnum } from '../../containers/enums/linksEnum'
import { sessionEnum } from '../../containers/enums/sessionEnum'

const PointsDeVente = () => {
	const fetchPDV = useQuery('PointsdeVente', () => fetchPointsdeVente(false), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})
	const { setSnackBar, session } = useContext(AppContext)
	const [openCreatePointVente, setOpenCreatePointVente] = useState<boolean>(false)
	const [creation, setCreation] = useState<boolean>(false)
	const [pointDeVente, setPointDeVente] =
		useState<PointDeVenteModel>(INITIAL_POINT_DE_VENTE)
	const navigate = useNavigate()

	if (fetchPDV.isLoading) {
		return <h1>chargement ...</h1>
	}

	const openModalCreatePointsdeVente = () => {
		setPointDeVente(INITIAL_POINT_DE_VENTE)
		setCreation(true)
		setOpenCreatePointVente(true)
	}

	const openModalAlterPointDeVente = (pointDeVente: PointDeVenteModel) => {
		setPointDeVente(pointDeVente)
		setCreation(false)
		setOpenCreatePointVente(true)
	}

	const closeModalCreatePointDeVente = () => {
		setOpenCreatePointVente(false)
		fetchPDV.refetch()
	}

	const activateDeactivate = (idPointsdeVente: number, activate: boolean) => {
		editStatusPDV(idPointsdeVente.toString(), activate).then((response: any) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'saisieRefProduit',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'saisieRefProduit',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				fetchPDV.refetch()
			}
		})
	}

	return (
		<Grid container item xs={12}>
			<CustomTable
				title="Gestion des points de vente"
				buttonCreateLabel="Créer un point de vente"
				buttonCreateOnClick={openModalCreatePointsdeVente}
				headRows={[
					{
						label: pointDeVenteTableLabelEnum.nom,
						id: getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.nom)
					},
					{
						label: pointDeVenteTableLabelEnum.distributeur,
						id: getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.distributeur)
					},
					{
						label: pointDeVenteTableLabelEnum.type,
						id: getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.type)
					},
					{
						label: pointDeVenteTableLabelEnum.identifiantTechnique,
						id: getKeyFromPointDeVenteTableLabelValue(
							pointDeVenteTableLabelEnum.identifiantTechnique
						)
					},
					{
						label: pointDeVenteTableLabelEnum.nombreDeDemandes,
						id: getKeyFromPointDeVenteTableLabelValue(
							pointDeVenteTableLabelEnum.nombreDeDemandes
						)
					},
					{
						label: pointDeVenteTableLabelEnum.modalsButtons,
						id: getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.modalsButtons)
					}
				]}
				rows={(fetchPDV.data ?? []).map((pointDeVente: PointDeVenteModel) => ({
					[getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.nom)]:
						pointDeVente.Nom_du_PDV,
					[getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.distributeur)]:
						pointDeVente.Nom_enseigne,
					[getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.type)]:
						pointDeVente.Type,
					[getKeyFromPointDeVenteTableLabelValue(
						pointDeVenteTableLabelEnum.identifiantTechnique
					)]: pointDeVente.Identifiant_technique_transmitterid,
					[getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.nombreDeDemandes)]:
						pointDeVente.nombreDemandes,
					[getKeyFromPointDeVenteTableLabelValue(pointDeVenteTableLabelEnum.modalsButtons)]: (
						<>
							<TableModaleButton
								bgcolor="buttonYellow"
								color="white"
								icon={<Edit />}
								onClick={() => openModalAlterPointDeVente(pointDeVente)}
							>
								Modifier
							</TableModaleButton>
							{pointDeVente.Pdv_active && (
								<>
									<TableModaleButton
										bgcolor="buttonRed"
										color="white"
										icon={<Close />}
										onClick={() => activateDeactivate(pointDeVente.Id_points_de_ventes, false)}
									>
										Désactiver
									</TableModaleButton>

									{session.role !== sessionEnum.responsablePointDeVente && (
										<TableModaleButton
											bgcolor="buttonBlue"
											color="white"
											icon={<Add />}
											onClick={() =>
												navigate(linksEnum.saisie, { state: { pointDeVente: pointDeVente } })
											}
										>
											Créer une demande
										</TableModaleButton>
									)}
								</>
							)}
							{!pointDeVente.Pdv_active && (
								<TableModaleButton
									bgcolor="green"
									color="white"
									icon={<Check />}
									onClick={() => activateDeactivate(pointDeVente.Id_points_de_ventes, true)}
								>
									Réactiver
								</TableModaleButton>
							)}
						</>
					)
				}))}
			/>
			<PopinPointDeVente
				onCloseDialog={closeModalCreatePointDeVente}
				open={openCreatePointVente}
				pointDeVente={pointDeVente}
				creation={creation}
			/>
		</Grid>
	)
}
export default PointsDeVente
