import Dialog from '../../../components/dialog/Dialog'
import React, { useContext } from 'react'
import { AppContext } from '../../../containers/context/AppContext'
import { Field, Form } from 'react-final-form'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import axios from 'axios'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import FormTextField from '../../../components/fields/FormTextField'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import Button from '../../../components/button/Button'
import { Box } from '@mui/material'

interface AcceptingProps {
	userId: number | null
	onSubmitLogin: () => void
}

type PopinDoubleAuthProps = AcceptingProps & DialogCommonProps

const PopinDoubleAuth: React.FC<PopinDoubleAuthProps> = ({
	onCloseDialog,
	open,
	userId,
	onSubmitLogin
}) => {
	const { setSnackBar } = useContext(AppContext)

	const validate = (values: any) => {
		const errors: any = {}

		if (!values.code) {
			errors.code = 'Code obligatoire'
		}

		return errors
	}

	const onSubmit = (values: any) => {
		const errors = {}

		axios({
			method: 'post',
			url: '/api/double-auth',
			data: {
				code: values.code,
				userId: userId
			}
		})
			.then(function (response) {
				setSnackBar({
					id: 'utilisateurs',
					message: response.data.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				onCloseDialog()
				onSubmitLogin()
			})
			.catch(function (error) {
				setSnackBar({
					id: 'utilisateurs',
					message: error.response.data.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			})

		return errors
	}

	return (
		<Dialog onCloseDialog={onCloseDialog} open={open}>
			<Box
				component="div"
				sx={{
					p: '15px'
				}}
			>
				<Box component="h3">
					Un code d'accès de double authentification vous a été envoyé. Il est nécessaire pour
					vous connecter.
				</Box>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					sx={{
						p: '15px'
					}}
					render={({ handleSubmit, errors, hasValidationErrors }) => (
						<form onSubmit={handleSubmit}>
							<Field
								name="code"
								type="text"
								label="Code d'accès de double authentification"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
								VALIDER
							</Button>
						</form>
					)}
				/>
			</Box>
		</Dialog>
	)
}

export default PopinDoubleAuth
