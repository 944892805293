import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import Loader from '../../../components/loader/Loader'
import Dialog from '../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../../components/fields/FormTextField'
import { cancelActivationFieldsEnum } from '../enums/cancelActivationEnum'
import { ActivationFormModel } from '../types/activationModel'
import { FormErrors } from '../../../components/fields/types/fieldsModel'
import { unflatten } from 'flat'
import { cancelActivation } from '../api/historiqueActivationsApi'

interface AcceptingProps {
	id: string
}

type PopinCancelActivationProps = AcceptingProps & DialogCommonProps

const PopinCancelActivation: React.FC<PopinCancelActivationProps> = ({
	onCloseDialog,
	open,
	id
}) => {
	const [loading, setLoading] = useState(false)

	const validate = (values: ActivationFormModel) => {
		const errors: FormErrors<ActivationFormModel> = {}

		if (
			!values[cancelActivationFieldsEnum.reason] ||
			values[cancelActivationFieldsEnum.reason].length === 0
		) {
			errors.Motif = 'Requis*'
		}

		return unflatten<typeof errors, ActivationFormModel>(errors)
	}

	const onSubmit = (values: ActivationFormModel) => {
		setLoading(true)

		return cancelActivation(id, values)
			.then(() => onCloseDialog())
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages
					return errorData[0]
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			sx={{
				minWidth: 600
			}}
		>
			<Grid sx={{ p: '20px 24px' }} container item xs={12}>
				<Typography
					sx={{
						color: 'texts.slateGrey',
						fontSize: 30,
						fontWeight: 300,
						mt: '20px',
						mb: '10px'
					}}
				>
					Voulez-vous vraiment annuler la demande ?
				</Typography>
			</Grid>

			<Grid container item xs={12}>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					render={({ handleSubmit, submitErrors }) => (
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<Grid sx={{ p: '20px 24px' }} container item xs={12}>
								<Field
									name={cancelActivationFieldsEnum.reason}
									type="text"
									placeholder="Donnez un motif d'annulation"
									component={FormTextField}
									sx={{
										marginBottom: '10px'
									}}
									multiline
									rows={5}
								/>

								{submitErrors && (
									<Box
										component="p"
										sx={{
											whiteSpace: 'break-spaces',
											color: 'texts.lightRed',
											fontSize: '12px'
										}}
									>
										{submitErrors}
									</Box>
								)}
							</Grid>

							<Grid
								sx={{
									p: '15px',
									bgcolor: 'backgrounds.buttonRedContainer'
								}}
								container
								item
								xs={12}
								justifyContent="center"
							>
								<Grid container item xs={6} justifyContent="left">
									<Button
										bgcolor="buttonRed"
										color="white"
										type={buttonTypesEnum.reset}
										onClick={onCloseDialog}
									>
										NON
									</Button>
								</Grid>
								<Grid container item xs={6} justifyContent="right">
									{loading ? (
										<Loader size={40} />
									) : (
										<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
											OUI
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</Grid>
		</Dialog>
	)
}

export default PopinCancelActivation
