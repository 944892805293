import React, { useMemo } from 'react'
import {
	columnsEnum,
	getKeyFromColumnValue
} from '../../../../../components/table/enums/columnsEnum'
import TableModaleButton from '../../../../../components/button/TableModaleButton'
import { Close, Edit } from '@mui/icons-material'
import CustomTable from '../../../../../components/table/Table'
import {
	categoriesTableHeadEnum,
	getKeyFromCategoriesTableHead
} from '../../../enums/popinCategoryEnum'
import { CategoryModel } from '../../../types/FAQModel'

interface AcceptingProps {
	categories: CategoryModel[]
	onOpenAdd: () => void
	onOpenEdit: (category: CategoryModel) => void
	onOpenDelete: (category: CategoryModel) => void
}

type ListCategoriesProps = AcceptingProps
const ListCategories: React.FC<ListCategoriesProps> = ({
	categories,
	onOpenAdd,
	onOpenEdit,
	onOpenDelete
}) => {
	const categoriesTableHeads = useMemo(
		() => [
			{
				label: categoriesTableHeadEnum.label,
				id: getKeyFromCategoriesTableHead(categoriesTableHeadEnum.label)
			},
			{
				label: columnsEnum.modalsButtons,
				id: getKeyFromColumnValue(columnsEnum.modalsButtons)
			}
		],
		[]
	)

	return (
		<CustomTable
			title="Gestion des catégories"
			headRows={categoriesTableHeads}
			buttonCreateLabel="Ajouter une catégorie"
			buttonCreateOnClick={onOpenAdd}
			rows={categories.map((category) =>
				categoriesTableHeads.reduce((acc, categoryTableHead) => {
					if (categoryTableHead.id === getKeyFromColumnValue(columnsEnum.modalsButtons)) {
						return {
							...acc,
							[categoryTableHead.id]: (
								<>
									<TableModaleButton
										bgcolor="buttonYellow"
										color="white"
										icon={<Edit />}
										onClick={() => onOpenEdit(category)}
									>
										Modifier
									</TableModaleButton>

									<TableModaleButton
										bgcolor="buttonRed"
										color="white"
										icon={<Close />}
										onClick={() => onOpenDelete(category)}
									>
										Supprimer
									</TableModaleButton>
								</>
							)
						}
					} else {
						return {
							...acc,
							[categoryTableHead.id]: category[categoryTableHead.id as keyof typeof category]
						}
					}
				}, {})
			)}
			tableContainerStyle={{ mt: 0 }}
			tableStyle={{ minWidth: 'auto' }}
		/>
	)
}

export default ListCategories
