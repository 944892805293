import { sessionEnum } from '../../../containers/enums/sessionEnum'
import { popinFAQFormFieldEnum } from '../enums/popinAddQuestionEnum'
import React from 'react'

export interface CategoryModel {
	id: number
	label: string
}

export const INITIAL_CATEGORY: CategoryModel = {
	id: 0,
	label: ''
}

export interface FAQFormModel {
	[popinFAQFormFieldEnum.id]: number
	[popinFAQFormFieldEnum.question]: string
	[popinFAQFormFieldEnum.answer]: string
	[popinFAQFormFieldEnum.category]: number
	[popinFAQFormFieldEnum.position]: number
	[popinFAQFormFieldEnum.relatedRole]: sessionEnum
}

export interface FAQModel {
	[popinFAQFormFieldEnum.id]: number
	[popinFAQFormFieldEnum.question]: string
	[popinFAQFormFieldEnum.answer]: string
	[popinFAQFormFieldEnum.category]: string
	[popinFAQFormFieldEnum.position]: number
	[popinFAQFormFieldEnum.relatedRole]: sessionEnum
}

export const INITIAL_FAQ: FAQModel = {
	[popinFAQFormFieldEnum.id]: -1,
	[popinFAQFormFieldEnum.question]: '',
	[popinFAQFormFieldEnum.answer]: '',
	[popinFAQFormFieldEnum.category]: INITIAL_CATEGORY.label,
	[popinFAQFormFieldEnum.position]: 1,
	[popinFAQFormFieldEnum.relatedRole]: sessionEnum.disconnected
}

export interface FAQContextModel {
	categories: CategoryModel[]
	setCategories: React.Dispatch<React.SetStateAction<CategoryModel[]>>
}
