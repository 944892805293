import React, { ChangeEventHandler } from 'react'
import { Input } from '@mui/material'
import { TextFieldAcceptingProps } from './FormTextField'

interface AcceptingProps {
	error?: string
	touched?: boolean
	value: string
	name: string
	onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

type CustomTextFieldProps = AcceptingProps & TextFieldAcceptingProps

const CustomTextField: React.FC<CustomTextFieldProps> = ({
	id,
	multiline,
	rows,
	maxLength,
	inputOnPaste,
	inputOnChange,
	inputOnKeyUp,
	placeholder,
	disablePaste,
	error,
	touched,
	value,
	onChange,
	name
}) => {
	return (
		<Input
			color="primary"
			multiline={multiline}
			rows={rows}
			inputProps={{
				maxLength: maxLength,
				onPaste: inputOnPaste,
				onChange: inputOnChange,
				onKeyUp: inputOnKeyUp
			}}
			sx={{
				width: '100%',
				marginBottom: '10px',
				fontSize: '14px',
				color: 'texts.grey',
				'input::-webkit-input-placeholder': {
					fontStyle: 'italic',
					fontSize: '12px',
					lineHeight: '20px',
					padding: '0 7px'
				},
				'input::-moz-placeholder': {
					fontStyle: 'italic',
					fontSize: '12px',
					lineHeight: '20px',
					padding: '0 7px'
				},
				'input:moz-placeholder': {
					fontStyle: 'italic',
					fontSize: '12px',
					lineHeight: '20px',
					padding: '0 7px'
				},
				'input:-ms-input-placeholder': {
					fontStyle: 'italic',
					fontSize: '12px',
					lineHeight: '20px',
					padding: '0 7px'
				},
				':before': {
					borderColor: `texts.${error && touched ? 'lightRed' : 'slateGrey'}`
				},
				':hover:not(.Mui-disabled):before': {
					borderWidth: '1px',
					borderColor: 'texts.blue'
				},
				':after': {
					borderWidth: '1px'
				}
			}}
			onPaste={(e) => disablePaste && e.preventDefault()}
			id={id}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			name={name}
		/>
	)
}

export default CustomTextField
