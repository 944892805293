import { createContext, useState } from 'react'
import { ConfigurationsModel, INITIAL_CONFIGURATIONS } from '../types/configurationsModel'
import { INITIAL_SESSION, SessionModel } from '../types/sessionModel'
import { AppContextModel } from '../types/appContextModel'
import {
	INITIAL_SNACKBAR,
	SnackBarModel
} from '../../components/snackBar/types/snackBarModel'

export const AppContext = createContext<AppContextModel>({
	configurations: INITIAL_CONFIGURATIONS,
	setConfigurations: (configurations) => {},
	session: INITIAL_SESSION,
	setSession: (sessions) => {},
	snackBar: INITIAL_SNACKBAR,
	setSnackBar: (snackBar) => {}
})

export const AppProvider: React.FC = ({ children }) => {
	const [configurations, setConfigurations] =
		useState<ConfigurationsModel>(INITIAL_CONFIGURATIONS)
	const [session, setSession] = useState<SessionModel>(INITIAL_SESSION)
	const [snackBar, setSnackBar] = useState<SnackBarModel>(INITIAL_SNACKBAR)

	return (
		<AppContext.Provider
			value={{
				configurations,
				setConfigurations,
				session,
				setSession,
				snackBar,
				setSnackBar
			}}
		>
			{children}
		</AppContext.Provider>
	)
}
