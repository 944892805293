import {
	Box,
	Button,
	ClickAwayListener,
	Grid,
	Grow,
	Link,
	MenuItem,
	MenuList,
	Paper,
	Popper
} from '@mui/material'
import React, { memo, useCallback, useContext, useRef, useState } from 'react'
import { AppContext } from '../../containers/context/AppContext'
import { Email, KeyboardArrowDown, MenuBook } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { linksEnum } from '../../containers/enums/linksEnum'
import axios from 'axios'
import { statutReponseEnum } from '../../containers/enums/statutReponseEnum'
import { INITIAL_SESSION } from '../../containers/types/sessionModel'
import ContactPopin from './components/ContactPopin'
import { sessionEnum } from '../../containers/enums/sessionEnum'
import SearchIcon from '@mui/icons-material/Search'
import UnitSearchPopin from '../unitSearch/UnitSearchPopin'

const MenuTop = () => {
	const { configurations, session, setSession } = useContext(AppContext)
	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)
	const navigate = useNavigate()
	const [openContactPopin, setOpenContactPopin] = useState(false)
	const [openSearchPopin, setOpenSearchPopin] = useState(false)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: any) => {
		setOpen(false)
	}

	function handleListKeyDown(event: any) {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		} else if (event.key === 'Escape') {
			setOpen(false)
		}
	}

	const handleDeconnexion = () => {
		axios.get('/api/deconnexion').then((logoutResponse: any) => {
			if (logoutResponse.data && logoutResponse.data.statut === statutReponseEnum.ok) {
				setSession(INITIAL_SESSION)
				navigate(linksEnum.login)
			}
		})
	}

	const closeUnitSearch = useCallback(() => setOpenSearchPopin(false), [])

	return (
		<Grid
			container
			item
			xs={12}
			justifyContent="space-between"
			sx={{
				backgroundColor: 'backgrounds.darkPurple',
				flex: '1 !important'
			}}
		>
			<Grid container item xs={2} justifyContent="center">
				<Box
					component="img"
					sx={{
						maxHeight: '2.9em',
						marginTop: 'auto',
						marginBottom: 'auto',
						alignContent: 'center',
						opacity: 0.75
					}}
					src="/img/logo/spirit1.png"
					alt="Logo Spirit of Cadeau"
				/>
			</Grid>

			<Box display="flex" alignItems="center">
				{(session.role === sessionEnum.agentDePointDeVente ||
					session.role === sessionEnum.responsableReseau) && (
					<Link
						underline="hover"
						onClick={() => setOpenSearchPopin(true)}
						sx={{
							color: 'texts.button',
							display: 'flex',
							alignItems: 'center',
							marginRight: 3,
							cursor: 'pointer',
							'&:hover': {
								color: 'texts.white'
							}
						}}
					>
						<SearchIcon />
						<Box
							component="span"
							sx={{
								marginLeft: 1
							}}
						>
							Recherche unitaire par carte
						</Box>
					</Link>
				)}

				{session.reapprovisionnementFile && session.reapprovisionnementFile.length > 0 && (
					<Link
						underline="hover"
						href={session.reapprovisionnementFile}
						target="_blank"
						sx={{
							color: 'texts.button',
							display: 'flex',
							alignItems: 'center',
							marginRight: 3,
							'&:hover': {
								color: 'texts.white'
							}
						}}
					>
						<Box
							component="img"
							src="img/logo/excel.svg"
							alt="Excel"
							width={24}
							height={24}
							sx={{
								mr: 1
							}}
						/>
						Bon de commande
					</Link>
				)}

				<Link
					underline="hover"
					onClick={() => setOpenContactPopin(true)}
					sx={{
						color: 'texts.button',
						display: 'flex',
						alignItems: 'center',
						marginRight: 3,
						cursor: 'pointer',
						'&:hover': {
							color: 'texts.white'
						}
					}}
				>
					<Email />
					<Box
						component="span"
						sx={{
							marginLeft: 1
						}}
					>
						Contact
					</Box>
				</Link>

				<Link
					underline="hover"
					href={configurations.links.manual}
					target="_blank"
					sx={{
						color: 'texts.button',
						display: 'flex',
						alignItems: 'center',
						marginRight: 3,
						'&:hover': {
							color: 'texts.white'
						}
					}}
				>
					<MenuBook
						sx={{
							marginRight: 1
						}}
					/>
					Manuel utilisateur
				</Link>

				<div>
					<Button
						ref={anchorRef}
						id="composition-button"
						aria-controls={open ? 'composition-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
						endIcon={<KeyboardArrowDown />}
						sx={{
							color: 'texts.button',
							textTransform: 'capitalize',
							marginRight: 2,
							'&:hover': {
								color: 'texts.white'
							}
						}}
					>
						<Box
							component="img"
							sx={{
								maxHeight: '2.9em',
								marginRight: 2,
								alignContent: 'center',
								borderRadius: '4px'
							}}
							src={session.logo ?? '/img/logo/CCDS.png'}
							alt={session.enseigne ? `Logo ${session.enseigne}` : 'Logo Spirit of Cadeau'}
						/>
						{`[${session.role}] - ${session.nom} ${session.prenom}`}
					</Button>
					<Popper
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						placement="bottom-start"
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList
											autoFocusItem={open}
											id="composition-menu"
											aria-labelledby="composition-button"
											onKeyDown={handleListKeyDown}
										>
											<MenuItem onClick={handleDeconnexion}>Deconnexion</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</Box>

			<ContactPopin
				open={openContactPopin}
				onCloseDialog={() => setOpenContactPopin(false)}
			/>
			<UnitSearchPopin open={openSearchPopin} onCloseDialog={closeUnitSearch} />
		</Grid>
	)
}

export default memo(MenuTop)
