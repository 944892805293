import React, { useContext } from 'react'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import Dialog from '../../../components/dialog/Dialog'
import { Box, Grid } from '@mui/material'
import { AppContext } from '../../../containers/context/AppContext'

const ContactPopin: React.FC<DialogCommonProps> = ({ open, onCloseDialog }) => {
	const { configurations } = useContext(AppContext)

	return (
		<Dialog onCloseDialog={onCloseDialog} open={open} title="Personne à contacter">
			<Grid sx={{ p: '20px 24px' }} container item xs={12}>
				<Box
					component="h1"
					sx={{
						mt: 0,
						color: 'texts.slateGrey',
						fontSize: 18
					}}
				>
					Vous rencontrez des difficultés ? <br />
					N'hésitez pas à contacter {configurations.links.contact.firstname}{' '}
					{configurations.links.contact.lastname} par mail :{' '}
					<Box
						component="a"
						href={`mailto:${configurations.links.contact.email}`}
						sx={{
							color: 'texts.slateGrey',
							fontSize: 18
						}}
					>
						{configurations.links.contact.email}
					</Box>
				</Box>
			</Grid>
		</Dialog>
	)
}

export default ContactPopin
