import Dialog from '../../../components/dialog/Dialog'
import React, { useContext, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { AppContext } from '../../../containers/context/AppContext'
import { UtilisateurModel } from '../types/utilisateurModel'
import { Form } from 'react-final-form'
import { ExpandMore } from '@mui/icons-material'
import FormUtilisateur from './FormUtilisateur'
import {
	utilisateursCreateType,
	utilisateursRoleMapType
} from '../enums/utilisateursEnum'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import axios from 'axios'
import { sessionEnum } from '../../../containers/enums/sessionEnum'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import { FormErrors } from '../../../components/fields/types/fieldsModel'
import { unflatten } from 'flat'

interface AcceptingProps {
	user: UtilisateurModel
}

type PopinUtilisateurProps = AcceptingProps & DialogCommonProps

const PopinUtilisateur: React.FC<PopinUtilisateurProps> = ({
	onCloseDialog,
	open,
	user
}) => {
	const { setSnackBar, session } = useContext(AppContext)

	const key = Object.keys(utilisateursRoleMapType).find(
		(roleMap) =>
			utilisateursRoleMapType[roleMap as keyof typeof utilisateursRoleMapType] === user?.Nom
	)
	const userType = utilisateursCreateType[key as keyof typeof utilisateursCreateType]

	const [opennedUserType, setOpennedUserType] = useState<utilisateursCreateType>()

	const validate = (values: UtilisateurModel) => {
		const errors: FormErrors<UtilisateurModel> = {}

		if (!values.Nom_user) {
			errors.Nom_user = 'Le nom de l\'utilisateur est obligatoire'
		}
		if (!values.Prenom_user) {
			errors.Prenom_user = 'Le prénom de l\'utilisateur est obligatoire'
		}
		if (!values.Email) {
			errors.Email = 'L\'email de l\'utilisateur est obligatoire'
		}

		if (
			opennedUserType === utilisateursCreateType.agent &&
			(!values.Id_points_de_ventes || values.Id_points_de_ventes.length === 0)
		) {
			errors.Id_points_de_ventes = 'Veuillez sélectionner au moins un point de vente'
		}

		console.log('errors', errors)

		return unflatten<typeof errors, UtilisateurModel>(errors)
	}

	const onSubmit = (values: UtilisateurModel) => {
		const errors: FormErrors<UtilisateurModel> = {}

		const url = `/api/users/${
			values.Id_user !== ''
				? values.Id_user
				: session.role === sessionEnum.responsableReseau
					? utilisateursCreateType.agent
					: opennedUserType
		}`

		axios({
			method: values.Id_user ? 'put' : 'post',
			url: url,
			data: values
		})
			.then(function (response) {
				setSnackBar({
					id: 'utilisateurs',
					message: response.data.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				onCloseDialog()
			})
			.catch(function (error) {
				setSnackBar({
					id: 'utilisateurs',
					message: error.response.data.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			})

		return errors
	}

	const accordionDetailCSS = {
		padding: '0'
	}

	const accordionSummaryCSS = {
		'& p': {
			transition: 'all .2s'
		},
		'&:hover p': {
			paddingLeft: '5px',
			transition: 'all .2s'
		}
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title={
				user.Id_user
					? 'Modification de l\'utilisateur'
					: session.role === sessionEnum.responsableReseau
						? 'Créer un agent'
						: 'Créer un compte'
			}
			backgroundColor="#5bc0de"
		>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={user}
				sx={{
					p: '15px'
				}}
				render={({ handleSubmit, errors, hasValidationErrors }) => (
					<form onSubmit={handleSubmit}>
						{user.Id_user ? (
							<FormUtilisateur onCloseDialog={onCloseDialog} user={user} userType={userType} />
						) : session.role === sessionEnum.responsableReseau ? (
							<FormUtilisateur
								onCloseDialog={onCloseDialog}
								user={user}
								userType={utilisateursCreateType.agent}
							/>
						) : (
							<>
								<Accordion
									expanded={opennedUserType === utilisateursCreateType.agent}
									onChange={() => setOpennedUserType(utilisateursCreateType.agent)}
								>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel-agent-pdv"
										id="panel-agent-pdv-header"
										sx={accordionSummaryCSS}
									>
										<Typography>Agent de point de vente</Typography>
									</AccordionSummary>
									<AccordionDetails sx={accordionDetailCSS}>
										<FormUtilisateur
											onCloseDialog={onCloseDialog}
											user={user}
											userType={opennedUserType}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={opennedUserType === utilisateursCreateType.responsable}
									onChange={() => setOpennedUserType(utilisateursCreateType.responsable)}
								>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel-responsable-reseau"
										id="panel-responsable-reseau-header"
										sx={accordionSummaryCSS}
									>
										<Typography>Responsable réseau</Typography>
									</AccordionSummary>
									<AccordionDetails sx={accordionDetailCSS}>
										<FormUtilisateur
											onCloseDialog={onCloseDialog}
											user={user}
											userType={opennedUserType}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={opennedUserType === utilisateursCreateType.valideur}
									onChange={() => setOpennedUserType(utilisateursCreateType.valideur)}
								>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel-validateur"
										id="panel-validateur-header"
										sx={accordionSummaryCSS}
									>
										<Typography>Validateur</Typography>
									</AccordionSummary>
									<AccordionDetails sx={accordionDetailCSS}>
										<FormUtilisateur
											onCloseDialog={onCloseDialog}
											user={user}
											userType={opennedUserType}
										/>
									</AccordionDetails>
								</Accordion>
								<Accordion
									expanded={opennedUserType === utilisateursCreateType.responsable_pdv}
									onChange={() => setOpennedUserType(utilisateursCreateType.responsable_pdv)}
								>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel-responsable-pdv"
										id="panel-responsable-pdv-header"
										sx={accordionSummaryCSS}
									>
										<Typography>Responsable point de vente</Typography>
									</AccordionSummary>
									<AccordionDetails sx={accordionDetailCSS}>
										<FormUtilisateur
											onCloseDialog={onCloseDialog}
											user={user}
											userType={opennedUserType}
										/>
									</AccordionDetails>
								</Accordion>
							</>
						)}
					</form>
				)}
			/>
		</Dialog>
	)
}

export default PopinUtilisateur
