import React, { useContext, useState } from 'react'
import { AppContext } from '../../../containers/context/AppContext'
import { submitModel } from '../../../types/registrationModel'
import { postForgotPassword } from '../api/loginAPI'
import { Field, Form } from 'react-final-form'
import { Box, Grid, Typography } from '@mui/material'
import FormLoginField from '../../../components/fields/FormLoginField'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'

interface AcceptingProps {
	setHasForgotPassword: (hasForgotPassword: boolean) => void
}

type ForgotPasswordProps = AcceptingProps

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setHasForgotPassword }) => {
	const [src, setSrc] = useState<string>('/api/connexion/captcha?' + new Date().getTime())
	const { setSnackBar } = useContext(AppContext)

	const onSubmit = (values: submitModel) => {
		return postForgotPassword(values).then((response: any) => {
			if (response.statut === 'OK') {
				setSnackBar({
					id: 'premiereConnexion',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
			} else {
				setSrc('/api/connexion/captcha?' + new Date().getTime())
				return response.messages[0]
			}
		})
	}

	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit, submitErrors }) => (
				<form onSubmit={handleSubmit}>
					<Grid container item xs={12}>
						<Grid
							sx={{
								bgcolor: 'backgrounds.white',
								width: '100%',
								padding: '10px'
							}}
							container
							item
							xs={12}
						>
							<Typography component="h1" sx={{ fontSize: 16, color: 'texts.green', m: 'auto' }}>
								MOT DE PASSE OUBLIÉ ?
							</Typography>

							<hr />

							{submitErrors && (
								<Grid
									container
									item
									xs={12}
									sx={{
										color: 'texts.lightRed',
										bgcolor: 'backgrounds.pink',
										m: '0px 15px 10px 15px',
										p: '15px 35px 15px 15px',
										borderLeft: '3px solid'
									}}
								>
									{submitErrors}
								</Grid>
							)}
							<Grid
								container
								item
								xs={12}
								sx={{
									marginBottom: '15px',
									mt: 1,
									color: 'texts.grey'
								}}
							>
								<Box>Entrez votre adresse email, nous allons vous aider à le réinitialiser.</Box>
							</Grid>
							<Grid container item xs={12}>
								<Field
									name="email"
									placeholder="Email"
									component={FormLoginField}
									sx={{
										marginBottom: 10
									}}
								/>
							</Grid>
							<Grid
								container
								item
								xs={12}
								sx={{
									margin: '15px',
									display: 'block'
								}}
							>
								<img src={src} className="captcha" alt="Captcha" />
							</Grid>

							<Grid container item xs={12}>
								<Field
									name="captcha"
									placeholder="Veuillez saisir les caractères ci-dessus"
									component={FormLoginField}
								/>
							</Grid>
						</Grid>

						<Grid container item xs={12}>
							<Grid
								sx={{
									p: '15px',
									bgcolor: 'backgrounds.blue'
								}}
								container
								item
								xs={12}
								justifyContent="center"
							>
								<Grid container item xs={6} justifyContent="left">
									<Button
										bgcolor="buttonRed"
										color="white"
										type={buttonTypesEnum.reset}
										onClick={() => setHasForgotPassword(false)}
									>
										ANNULER
									</Button>
								</Grid>
								<Grid container item xs={6} justifyContent="right">
									<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
										RÉINITIALISER
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	)
}

export default ForgotPassword
