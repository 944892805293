import React, { useMemo } from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { UtilisateurModel } from '../types/utilisateurModel'
import { Field } from 'react-final-form'
import FormTextField from '../../../components/fields/FormTextField'
import FormSelectField from '../../../components/fields/FormSelectField'
import { fetchPointsdeVente } from '../../pointsDeVente/api/pointsDeVenteApi'
import { useQuery } from 'react-query'
import { FormSelectFieldModel } from '../../../components/fields/types/formSelectFieldModel'
import { fetchDistributeurs } from '../../enseignes/api/enseignesApi'
import { utilisateursCreateType, utilisateursFormField } from '../enums/utilisateursEnum'

interface AcceptingProps {
	onCloseDialog: () => void
	user: UtilisateurModel
	userType: utilisateursCreateType | undefined
}

type FormUtilisateurProps = AcceptingProps

const FormUtilisateur: React.FC<FormUtilisateurProps> = ({
	onCloseDialog,
	user,
	userType
}) => {
	// Récupération des points de vente.
	const fetchPDV = useQuery('PointsdeVente', () => fetchPointsdeVente(false), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})
	const fetchDistributeur = useQuery('Distributeurs', fetchDistributeurs, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})

	const pdvOptions: FormSelectFieldModel[] = useMemo(
		() =>
			fetchPDV.data
				? fetchPDV.data.map((pdv) => ({
					label: pdv.Nom_du_PDV,
					value: pdv.Id_points_de_ventes
				  }))
				: [],
		[fetchPDV.data]
	)

	const distributeurOptions: FormSelectFieldModel[] = useMemo(
		() =>
			fetchDistributeur.data
				? fetchDistributeur.data.map((distributeur) => ({
					value: distributeur.Id_enseignes,
					label: distributeur.Nom_enseigne
				  }))
				: [],
		[fetchDistributeur.data]
	)

	return (
		<>
			<Grid
				container
				item
				xs={12}
				sx={{
					padding: '8px 16px 16px'
				}}
			>
				<Field
					name={utilisateursFormField.Nom_user}
					type="text"
					label="Nom de l'utilisateur"
					component={FormTextField}
					sx={{
						marginBottom: '10px'
					}}
					value={user?.Nom_user}
				/>
				<Field
					name={utilisateursFormField.Prenom_user}
					type="text"
					label="Prénom de l'utilisateur"
					component={FormTextField}
					sx={{
						marginBottom: '10px'
					}}
					value={user?.Prenom_user}
				/>
				<Field
					name={utilisateursFormField.Email}
					type="text"
					label="E-mail de l'utilisateur"
					component={FormTextField}
					sx={{
						marginBottom: '10px'
					}}
					value={user?.Email}
				/>
				{userType === utilisateursCreateType.agent && !fetchPDV.isLoading && (
					<Field
						name={utilisateursFormField.Id_points_de_ventes}
						label="Sélectionner au moins un point de vente"
						component={FormSelectField}
						options={pdvOptions}
						multipleOptions={true}
						loading={fetchPDV.isLoading}
						sx={{
							marginBottom: '10px'
						}}
						defaultValue={user.Id_points_de_ventes}
					/>
				)}
				{userType === utilisateursCreateType.responsable && !fetchDistributeur.isLoading && (
					<Field
						name={utilisateursFormField.Id_enseignes}
						label="Sélectionner au moins un distributeur"
						component={FormSelectField}
						options={distributeurOptions}
						loading={fetchDistributeur.isLoading}
						sx={{
							marginBottom: '10px'
						}}
						defaultValue={
							user.Nom_enseigne && user.Nom_enseigne.length > 0
								? distributeurOptions.filter(
									(distributeur) => distributeur.label === user.Nom_enseigne
								  )[0].value ?? undefined
								: undefined
						}
					/>
				)}
			</Grid>
			<Grid
				sx={{
					p: '15px',
					bgcolor: 'backgrounds.blue'
				}}
				container
				item
				xs={12}
				justifyContent="center"
			>
				<Grid container item xs={6} justifyContent="left">
					<Button
						bgcolor="buttonRed"
						color="white"
						type={buttonTypesEnum.reset}
						onClick={onCloseDialog}
					>
						ANNULER
					</Button>
				</Grid>
				<Grid container item xs={6} justifyContent="right">
					<Button
						bgcolor={user.Id_user ? 'orange' : 'green'}
						color="white"
						type={buttonTypesEnum.submit}
					>
						{user.Id_user ? 'MODIFIER' : 'VALIDER'}
					</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default FormUtilisateur
