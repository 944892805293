export interface EnseigneModel {
	Enseigne_active: boolean
	Ezp_id: string
	Ezp_mdp: string
	Id_enseignes: string
	Logo: string
	Montant_max: number
	Montant_min: number
	Nom_enseigne: string
	Nombre_max_cartes: number
	Nombre_saisie: number
	nombreDePointsDeVentes: number
	nombreDeReferencesProduit: number
	reapprovisionnement?: File[]
}

export const INITIAL_ENSEIGNE: EnseigneModel = {
	Enseigne_active: true,
	Ezp_id: '',
	Ezp_mdp: '',
	Id_enseignes: '',
	Logo: '',
	Montant_max: 0,
	Montant_min: 0,
	Nom_enseigne: '',
	Nombre_max_cartes: 0,
	Nombre_saisie: 0,
	nombreDePointsDeVentes: 0,
	nombreDeReferencesProduit: 0
}
