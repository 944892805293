import React, { memo, useState } from 'react'
import { DialogCommonProps } from '../../components/dialog/types/dialogModel'
import { Box, Grid } from '@mui/material'
import Dialog from '../../components/dialog/Dialog'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import Button from '../../components/button/Button'
import { buttonTypesEnum } from '../../components/button/enums/buttonTypesEnum'
import Loader from '../../components/loader/Loader'
import { UnitSearchFormModel, UnitSearchResponseModel } from './types/unitSearchModel'
import { FormErrors } from '../../components/fields/types/fieldsModel'
import { unitSearchFormEnum } from './enums/unitSearchEnum'
import { unflatten } from 'flat'
import { getUnitSearch } from './api/unitSearchAPI'

const UnitSearchPopin: React.FC<DialogCommonProps> = ({ open, onCloseDialog }) => {
	const [loading, setLoading] = useState(false)
	const [cardDetails, setCardDetails] = useState<UnitSearchResponseModel | undefined>(
		undefined
	)

	const validate = (values: UnitSearchFormModel) => {
		const errors: FormErrors<UnitSearchFormModel> = {}

		if (
			!values[unitSearchFormEnum.cardId] ||
			values[unitSearchFormEnum.cardId].length === 0
		) {
			errors.cardId = 'Requis*'
		} else if (values[unitSearchFormEnum.cardId].length !== 16) {
			errors.cardId = 'La carte doit faire 16 caractères'
		}

		return unflatten<typeof errors, UnitSearchFormModel>(errors)
	}

	const onSubmit = (values: UnitSearchFormModel) => {
		setLoading(true)

		return getUnitSearch(values[unitSearchFormEnum.cardId])
			.then((response) => setCardDetails(response.data))
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages
					return errorData[0]
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title="Recherche unitaire par carte"
			sx={{
				minWidth: '400px'
			}}
		>
			<Grid container item xs={12}>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					render={({ handleSubmit, submitErrors }) => (
						<form
							onSubmit={handleSubmit}
							style={{
								width: '100%'
							}}
						>
							<Grid sx={{ p: '20px 24px' }} container item xs={12}>
								<Field
									name={unitSearchFormEnum.cardId}
									type="text"
									label="Identifiant de la carte"
									component={FormTextField}
									sx={{
										marginBottom: '10px'
									}}
									maxLength={16}
								/>

								{/* S'il y a des erreurs lors de la soumission */}
								{submitErrors && (
									<Box
										component="p"
										sx={{
											whiteSpace: 'break-spaces',
											color: 'texts.lightRed',
											fontSize: '12px'
										}}
									>
										{submitErrors}
									</Box>
								)}
							</Grid>

							{/* Si l'envoi est un succès et que l'on a un retour */}
							{cardDetails && (
								<Grid sx={{ p: '20px 24px' }} container item xs={12}>
									<Grid item xs={6}>
										<p>
											<strong>Statut de la carte :</strong>
										</p>
										<p>{cardDetails.cardStatus}</p>
									</Grid>

									<Grid item xs={6}>
										<p>
											<strong>Raison de l'anomalie :</strong>
										</p>
										<p>{cardDetails.reason ?? '-'}</p>
									</Grid>
								</Grid>
							)}
							<Grid
								sx={{
									p: '15px',
									bgcolor: 'backgrounds.blue'
								}}
								container
								item
								xs={12}
								justifyContent="center"
							>
								<Grid container item xs={6} justifyContent="left">
									<Button
										bgcolor="buttonRed"
										color="white"
										type={buttonTypesEnum.reset}
										onClick={onCloseDialog}
									>
										ANNULER
									</Button>
								</Grid>
								<Grid container item xs={6} justifyContent="right">
									{loading ? (
										<Loader size={40} />
									) : (
										<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
											VALIDER
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</Grid>
		</Dialog>
	)
}

export default memo(UnitSearchPopin)
