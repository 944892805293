import axios from 'axios'
import { statutReponseEnum } from '../../../containers/enums/statutReponseEnum'
import { carteInactiveModel } from '../types/carteInactiveModel'

export const postDemandesVerifyApi = (
	lots: any
): Promise<{
	messages: string
	statut: statutReponseEnum
	cartesNonValide: carteInactiveModel[] | undefined
}> =>
	axios
		.post('/api/demandes/verifier', lots)
		.then((res) => res.data)
		.catch((error) => error.response.data)
