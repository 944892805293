export enum utilisateursTableLabelEnum {
	nom = 'Nom',
	prenom = 'Prénom',
	email = 'Email',
	fonction = 'Fonction',
	pointDeVente = 'Point de vente',
	distributeur = 'Distributeur',
	modalsButtons = ''
}

export enum utilisateursCreateType {
	agent = 'agent',
	responsable = 'responsable',
	valideur = 'valideur',
	responsable_pdv = 'responsable_pdv'
}

export enum utilisateursRoleMapType {
	agent = 'agent de point de vente',
	responsable = 'responsable reseau',
	valideur = 'valideur',
	responsable_pdv = 'responsable point de vente'
}

export const getKeyFromUtilisateursTableLabelValue = (
	value: utilisateursTableLabelEnum
) =>
	Object.keys(utilisateursTableLabelEnum).find(
		(utilisateursTableLabel) =>
			utilisateursTableLabelEnum[
				utilisateursTableLabel as keyof typeof utilisateursTableLabelEnum
			] === value
	) ?? ''

export enum utilisateursFormField {
	Nom_user = 'Nom_user',
	Prenom_user = 'Prenom_user',
	Email = 'Email',
	Id_points_de_ventes = 'Id_points_de_ventes',
	Id_enseignes = 'Id_enseignes'
}
