import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Box } from '@mui/material'
import CustomTextField from './CustomTextField'

export interface TextFieldAcceptingProps {
	id: string
	label?: string
	placeholder?: string
	multiline?: boolean
	maxLength?: number
	disablePaste?: boolean
	inputOnChange?: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	inputOnKeyUp?: (
		event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void
	inputOnPaste?: () => void
	fontSize?: string
	rows?: number
}

type FormTextFieldProps = FieldRenderProps<string> & TextFieldAcceptingProps

const FormTextField: React.FC<FormTextFieldProps> = ({
	input,
	meta: { touched, error },
	id,
	label,
	placeholder,
	multiline,
	maxLength,
	disablePaste = false,
	inputOnChange,
	inputOnPaste,
	inputOnKeyUp,
	fontSize = '14px',
	rows = 5
}) => (
	<>
		{label && (
			<span
				style={{
					width: '100%',
					paddingRight: 15,
					paddingTop: 7,
					fontSize: `${fontSize}`,
					marginBottom: 0,
					marginLeft: 0,
					boxSizing: 'border-box',
					textAlign: 'left'
				}}
			>
				{label}
			</span>
		)}
		<CustomTextField
			id={id}
			multiline={multiline}
			rows={rows}
			maxLength={maxLength}
			inputOnPaste={inputOnPaste}
			inputOnChange={inputOnChange}
			inputOnKeyUp={inputOnKeyUp}
			placeholder={placeholder}
			value={input.value}
			onChange={input.onChange}
			name={input.name}
			disablePaste={disablePaste}
			error={error}
			touched={touched}
		/>

		{touched && error && (
			<Box
				sx={{
					color: 'texts.lightRed',
					fontSize: '12px',
					width: '100%',
					textAlign: 'center'
				}}
			>
				{error}
			</Box>
		)}
	</>
)

export default FormTextField
