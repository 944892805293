import Dialog from '../../../components/dialog/Dialog'
import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { AppContext } from '../../../containers/context/AppContext'
import { UtilisateurModel } from '../types/utilisateurModel'
import { disableUser, enableUser } from '../api/utilisateursApi'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	activation: boolean
	user: UtilisateurModel
}

type PopinDesactivationUtilisateurProps = AcceptingProps

const PopinDesactivationUtilisateur: React.FC<PopinDesactivationUtilisateurProps> = ({
	onCloseDialog,
	open,
	activation,
	user
}) => {
	const { setSnackBar } = useContext(AppContext)

	const onSubmit = () => {
		const fetch = activation ? enableUser(user.Id_user) : disableUser(user.Id_user)

		fetch.then((response: { code: number; messages: string[] }) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'users',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'users',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
			}
			onCloseDialog()
		})
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title={activation ? 'Réactiver un utilisateur' : 'Désactiver un utilisateur'}
			backgroundColor={activation ? '#5cb85c' : '#d9534f'}
		>
			<>
				<Grid sx={{ p: '20px 24px' }} container item xs={12}>
					Voulez-vous vraiment {activation ? 'réactiver' : 'désactiver'}{' '}
					l'utilisateur&nbsp;&nbsp;
					<b>
						{user.Nom_user.toUpperCase()} {user.Prenom_user}
					</b>{' '}
					?
				</Grid>
				<Grid
					sx={{
						p: '15px',
						bgcolor: 'backgrounds.blue'
					}}
					container
					item
					xs={12}
					justifyContent="center"
				>
					<Grid container item xs={6} justifyContent="left">
						<Button
							bgcolor="buttonRed"
							color="white"
							type={buttonTypesEnum.reset}
							onClick={onCloseDialog}
						>
							ANNULER
						</Button>
					</Grid>
					<Grid container item xs={6} justifyContent="right">
						<Button
							bgcolor="green"
							color="white"
							type={buttonTypesEnum.submit}
							onClick={onSubmit}
						>
							{activation ? 'RÉACTIVER' : 'DÉSACTIVER'}
						</Button>
					</Grid>
				</Grid>
			</>
		</Dialog>
	)
}

export default PopinDesactivationUtilisateur
