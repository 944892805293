import axios from 'axios'
import { RefProduitModel } from '../types/refProduitModel'

export const getInfoRefProduitApi = (
	refProduit: string,
	enseigneId: number
): Promise<RefProduitModel | undefined> =>
	axios(`/api/ref_produit/${refProduit}/${enseigneId}`)
		.then((res) => res.data[0])
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data)
				console.log(error.response.status)
				console.log(error.response.headers)
			} else if (error.request) {
				console.log(error.request)
			} else {
				console.log('Error', error.message)
			}
			console.log(error.config)
		})
