export interface LotModel {
	Numero_logistique_premiere_carte: string | undefined
	Numero_logistique_derniere_carte: string | undefined
	Montant_par_carte: number | undefined
	Reference: string | undefined
}

export interface FormLotsModel {
	pdv: number
	lots: LotModel[]
	commentaire: string
}

export const emptyLot = {
	Numero_logistique_premiere_carte: undefined,
	Numero_logistique_derniere_carte: undefined,
	Montant_par_carte: undefined,
	Reference: undefined
}

export const initFormLots = {
	pdv: 0,
	lots: [emptyLot],
	commentaire: ''
}
