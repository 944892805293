import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input } from '@mui/material'

interface AcceptingProps {
	id: string
	label?: string
	placeholder?: string
	multiline?: boolean
	maxLength?: number
	type?: string
	disablePaste: boolean
	adornment?: string
	inputOnChange?: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	inputOnKeyUp?: (
		event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void
	inputOnPaste?: () => void
	showPassword?: boolean
	seePassword?: boolean
	handleClickShowPassword?: () => void
}

type FormLoginFieldProps = FieldRenderProps<string> & AcceptingProps

const FormLoginField: React.FC<FormLoginFieldProps> = ({
	input,
	meta: { touched, error },
	id,
	label,
	placeholder,
	multiline,
	maxLength,
	type = 'text',
	disablePaste = false,
	adornment,
	inputOnChange,
	inputOnPaste,
	inputOnKeyUp,
	showPassword,
	seePassword,
	handleClickShowPassword
}) => {
	const handleMouseDownPassword = (event: any) => {
		event.preventDefault()
	}

	return (
		<>
			{label && (
				<span
					style={{
						width: '25%',
						paddingRight: 15,
						paddingTop: 7,
						textAlign: 'right',
						marginBottom: 0,
						marginLeft: 0,
						boxSizing: 'border-box'
					}}
				>
					{label}
				</span>
			)}
			<Input
				id={id}
				color="primary"
				multiline={multiline}
				inputProps={{
					maxLength: maxLength,
					onPaste: inputOnPaste,
					onChange: inputOnChange,
					onKeyUp: inputOnKeyUp
				}}
				sx={{
					width: label ? '71%' : '100%',
					marginBottom: '10px',
					fontSize: '14px',
					color: 'texts.grey',
					'input::-webkit-input-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px',
						padding: '0 7px'
					},
					'input::-moz-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px',
						padding: '0 7px'
					},
					'input:moz-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px',
						padding: '0 7px'
					},
					'input:-ms-input-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px',
						padding: '0 7px'
					}
				}}
				placeholder={placeholder}
				onPaste={(e) => disablePaste && e.preventDefault()}
				{...input}
			/>

			{touched && error && <span>{error}</span>}
		</>
	)
}

export default FormLoginField
