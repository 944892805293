import axios from 'axios'
import { ReferenceProduitModel } from '../types/referenceProduitModel'

export const fetchReferencesProduits = (id: string): Promise<ReferenceProduitModel[]> =>
	axios('/api/ref_produit_enseigne/' + id)
		.then((res) => res.data)
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data)
				console.log(error.response.status)
				console.log(error.response.headers)
			} else if (error.request) {
				console.log(error.request)
			} else {
				console.log('Error', error.message)
			}
			console.log(error.config)
		})

export const addRefCard = (ref_prod: string, data: FormData) =>
	axios.post(`/api/ref_produit/${ref_prod}`, data).then((response) => response.data)
export const addRefCardAmount = (ref_prod: string, data: FormData) =>
	axios
		.post(`/api/ref_produit/produit/${ref_prod}`, data)
		.then((response) => response.data)
export const deleteRefCard = (ref_prod: string, enseigne: string) =>
	axios
		.put(`/api/ref_produit/${ref_prod}/${enseigne}/supprimer`)
		.then((response) => response.data)
