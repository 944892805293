import React, { useCallback, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid } from '@mui/material'
import { useQuery } from 'react-query'
import { getQuestions } from '../api/FAQAdminAPI'
import { FAQModel } from '../types/FAQModel'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { popinFAQFormFieldEnum } from '../enums/popinAddQuestionEnum'

const FAQCommon = () => {
	const [expanded, setExpanded] = useState(-1)
	const [filterByCategory, setFilterByCategory] = useState<string[]>([])

	const handleChange = useCallback(
		(questionSelected: number) => (event: React.SyntheticEvent, isExpanded: boolean) =>
			setExpanded(isExpanded ? questionSelected : -1),
		[]
	)

	const updateCategoryFiltered = useCallback(
		(categoryFilterSelected: string) =>
			setFilterByCategory((prevFilterByCategory) => {
				// Vérification de la présence de la catégorie dans le filtre
				if (prevFilterByCategory.includes(categoryFilterSelected)) {
					// Si elle est présente on l'enlève
					return prevFilterByCategory.filter(
						(prevFilterByCategoryValue) => prevFilterByCategoryValue !== categoryFilterSelected
					)
				}

				// Sinon on l'ajoute aux catégories filtrées
				return [...prevFilterByCategory, categoryFilterSelected]
			}),
		[]
	)

	// Récupération des FAQs de l'utilisateur
	const FAQResult = useQuery<FAQModel[]>('faq', getQuestions, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})

	if (FAQResult.isFetching) {
		return <h1>chargement ...</h1>
	}

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				mt: 2
			}}
		>
			<Grid container item xs={12}>
				{(FAQResult.data ?? [])
					// On récupère les catégories des questions, en faisant en sorte de ne pas avoir de doublon dans le résultat final
					.reduce((acc: string[], value: FAQModel) => {
						if (!acc.includes(value[popinFAQFormFieldEnum.category])) {
							return [...acc, value[popinFAQFormFieldEnum.category]]
						}

						return acc
					}, [])
					.map((category) => (
						<Chip
							label={category}
							key={category}
							sx={{
								mr: 1,
								bgcolor: filterByCategory.includes(category)
									? 'backgrounds.blue'
									: 'backgrounds.white',
								color: filterByCategory.includes(category) ? 'texts.white' : 'texts.slateGrey',
								mb: 2,
								fontSize: 14,
								cursor: 'pointer'
							}}
							onClick={() => updateCategoryFiltered(category)}
						/>
					))}
			</Grid>

			<Grid container item xs={12}>
				{(FAQResult.data ?? [])
					// Tri selon l'ordre défini par l'administrateur
					.sort(
						(a: FAQModel, b: FAQModel) =>
							a[popinFAQFormFieldEnum.position] - b[popinFAQFormFieldEnum.position]
					)
					.filter((question: FAQModel) =>
						filterByCategory.length === 0 ? true : filterByCategory.includes(question.category)
					)
					.map((question: FAQModel) => (
						// Affichage d'un accordéon pour chaque question
						<Accordion
							expanded={expanded === question[popinFAQFormFieldEnum.id]}
							onChange={handleChange(question[popinFAQFormFieldEnum.id])}
							sx={{
								width: '100%'
							}}
							key={question[popinFAQFormFieldEnum.id]}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`${question[popinFAQFormFieldEnum.id]}-accordion-header`}
								id={`${question[popinFAQFormFieldEnum.id]}-accordion-header`}
								sx={{
									'& > div:first-of-type': {
										justifyContent: 'space-between'
									}
								}}
							>
								<Typography sx={{ color: 'texts.slateGrey', fontSize: 18, fontWeight: 600 }}>
									{question[popinFAQFormFieldEnum.question]}
								</Typography>
								<Typography sx={{ color: 'texts.grey', mr: 3 }}>
									{question[popinFAQFormFieldEnum.category]}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography sx={{ color: 'texts.grey', fontSize: 16 }}>
									{question[popinFAQFormFieldEnum.answer]}
								</Typography>
							</AccordionDetails>
						</Accordion>
					))}
			</Grid>
		</Grid>
	)
}

export default FAQCommon
