import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@mui/material'

interface AcceptingProps {
	size?: number | string
}

const Loader: React.FC<AcceptingProps> = ({ size }) => (
	<Box
		component="div"
		sx={{
			'& svg': {
				color: 'texts.green'
			}
		}}
	>
		<CircularProgress size={size} />
	</Box>
)

export default Loader
