import React, { useContext, useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import { Field, Form } from 'react-final-form'
import {
	getKeyFromPopinFAQFormFieldValue,
	popinFAQFormFieldEnum
} from '../../enums/popinAddQuestionEnum'
import FormTextField from '../../../../components/fields/FormTextField'
import FormSelectField from '../../../../components/fields/FormSelectField'
import {
	getKeyFromSessionValue,
	sessionEnum
} from '../../../../containers/enums/sessionEnum'
import Button from '../../../../components/button/Button'
import { buttonTypesEnum } from '../../../../components/button/enums/buttonTypesEnum'
import Loader from '../../../../components/loader/Loader'
import { FAQFormModel, FAQModel } from '../../types/FAQModel'
import { FormErrors } from '../../../../components/fields/types/fieldsModel'
import { unflatten } from 'flat'
import { FAQContext } from '../../context/FAQContext'
import { FormSelectFieldModel } from '../../../../components/fields/types/formSelectFieldModel'

interface AcceptingProps {
	onSubmit: (values: FAQModel) => Promise<any>
	loading: boolean
	onCloseDialog: () => void
	initialValues?: FAQFormModel
}

type FAQFormComponentProps = AcceptingProps

const FAQFormComponent: React.FC<FAQFormComponentProps> = ({
	onSubmit,
	loading,
	onCloseDialog,
	initialValues
}) => {
	const { categories } = useContext(FAQContext)

	const validate = (values: FAQModel) => {
		let errors: FormErrors<FAQModel> = {}

		// On parcourt toutes les clés de l'enum, sauf id, car tous les éléments sont requis
		Object.keys(popinFAQFormFieldEnum)
			.filter(
				(formFieldKey) =>
					formFieldKey !== getKeyFromPopinFAQFormFieldValue(popinFAQFormFieldEnum.id)
			)
			.forEach((formFieldKey) => {
				// Si l'élément n'existe pas dans les values ou s'il est vide, on retourne une erreur sur le champ
				if (
					!values[formFieldKey as keyof typeof values] ||
					values[formFieldKey as keyof typeof values].toString().length === 0
				) {
					errors = {
						...errors,
						[popinFAQFormFieldEnum[formFieldKey as keyof typeof popinFAQFormFieldEnum]]:
							'Requis*'
					}
				}
			})

		return unflatten<typeof errors, FAQModel>(errors)
	}

	const categoryOptions: FormSelectFieldModel[] = useMemo(
		() =>
			categories.map((category) => ({
				value: category.id,
				label: category.label
			})),
		[categories]
	)

	const relatedRoleOptions: FormSelectFieldModel[] = useMemo(
		() =>
			Object.keys(sessionEnum)
				.filter(
					(sessionKey) => sessionKey !== getKeyFromSessionValue(sessionEnum.disconnected)
				)
				.map((sessionKey) => ({
					label: sessionEnum[sessionKey as keyof typeof sessionEnum],
					value: sessionKey
				})),
		[sessionEnum]
	)

	return (
		<Form
			onSubmit={onSubmit}
			validate={validate}
			initialValues={
				initialValues ?? {
					[popinFAQFormFieldEnum.category]: categoryOptions[0].value,
					[popinFAQFormFieldEnum.relatedRole]: relatedRoleOptions[0].value
				}
			}
			render={({ handleSubmit, submitErrors }) => (
				<form onSubmit={handleSubmit}>
					<Grid sx={{ p: '20px 24px' }} container item xs={12}>
						<Field
							name={popinFAQFormFieldEnum.question}
							type="text"
							label="Question"
							component={FormTextField}
							sx={{
								marginBottom: '10px'
							}}
						/>
						<Field
							name={popinFAQFormFieldEnum.answer}
							type="text"
							label="Réponse"
							component={FormTextField}
							sx={{
								marginBottom: '10px'
							}}
							multiline
						/>
						<Field
							name={popinFAQFormFieldEnum.category}
							label="Catégorie"
							component={FormSelectField}
							options={categoryOptions}
							sx={{
								marginBottom: '10px'
							}}
						/>
						<Field
							name={popinFAQFormFieldEnum.position}
							type="number"
							label="Ordre"
							component={FormTextField}
							sx={{
								marginBottom: '10px'
							}}
						/>
						<Field
							name={popinFAQFormFieldEnum.relatedRole}
							type="number"
							label="Question destinée à "
							component={FormSelectField}
							options={relatedRoleOptions}
							sx={{
								marginBottom: '10px'
							}}
						/>

						{submitErrors && (
							<Box
								component="p"
								sx={{
									whiteSpace: 'break-spaces',
									color: 'texts.lightRed',
									fontSize: '12px'
								}}
							>
								{submitErrors}
							</Box>
						)}
					</Grid>

					<Grid
						sx={{
							p: '15px',
							bgcolor: 'backgrounds.blue'
						}}
						container
						item
						xs={12}
						justifyContent="center"
					>
						<Grid container item xs={6} justifyContent="left">
							<Button
								bgcolor="buttonRed"
								color="white"
								type={buttonTypesEnum.reset}
								onClick={onCloseDialog}
							>
								ANNULER
							</Button>
						</Grid>
						<Grid container item xs={6} justifyContent="right">
							{loading ? (
								<Loader size={40} />
							) : (
								<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
									VALIDER
								</Button>
							)}
						</Grid>
					</Grid>
				</form>
			)}
		/>
	)
}

export default FAQFormComponent
