import { createContext, useState } from 'react'
import { CategoryModel, FAQContextModel } from '../types/FAQModel'

export const FAQContext = createContext<FAQContextModel>({
	categories: [],
	setCategories: (categories) => {}
})

export const FAQProvider: React.FC = ({ children }) => {
	const [categories, setCategories] = useState<CategoryModel[]>([])

	return (
		<FAQContext.Provider
			value={{
				categories,
				setCategories
			}}
		>
			{children}
		</FAQContext.Provider>
	)
}
