export enum categoriesTableHeadEnum {
	label = 'Libellé'
}

export enum categoriesContentToDisplayEnum {
	listCategories = 'listCategories',
	addCategory = 'addCategory',
	editCategory = 'editCategory',
	deleteCategory = 'deleteCategory'
}

export enum categoriesFormFieldEnum {
	label = 'label'
}

export const getKeyFromCategoriesTableHead = (value: categoriesTableHeadEnum) =>
	Object.keys(categoriesTableHeadEnum).find(
		(categoryTableHead) =>
			categoriesTableHeadEnum[categoryTableHead as keyof typeof categoriesTableHeadEnum] ===
			value
	) ?? ''
