import axios from 'axios'
import { CarteWithRefProduitModel } from '../types/refProduitModel'
import { LotModel } from '../types/lotModel'

export const getRefsProduitsApi = (lot: LotModel): Promise<CarteWithRefProduitModel[]> =>
	axios(
		`/api/ref_produit/cartes/${lot.Numero_logistique_premiere_carte}/${lot.Numero_logistique_derniere_carte}`
	)
		.then((res) => res.data)
		.catch((error) => {
			console.log(error)
		})
