import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import CustomTable from '../../components/table/Table'
import { fetchHistoriqueActivations } from './api/historiqueActivationsApi'
import {
	getKeyFromHistoriqueActivationTableLabelValue,
	historiqueActivationTableLabelEnum
} from './enums/historiqueActivationEnum'
import {
	ActivationModel,
	CancelPopinModel,
	DetailsPopinModel,
	INITIAL_CANCEL_POPIN,
	INITIAL_DETAILS_POPIN
} from './types/activationModel'
import CommentIcon from '@mui/icons-material/Comment'
import {
	columnsEnum,
	getKeyFromColumnValue
} from '../../components/table/enums/columnsEnum'
import TableModaleButton from '../../components/button/TableModaleButton'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { demandeStatusEnum } from './enums/demandeStatusEnum'
import CloseIcon from '@mui/icons-material/Close'
import PopinCancelActivation from './components/PopinCancelActivation'
import PopinDetailsActivation from './components/PopinDetailsActivation'
import { getKeyFromEnumValue } from '../../utils/utils'
import { sessionEnum } from '../../containers/enums/sessionEnum'
import { AppContext } from '../../containers/context/AppContext'
import FormSelectField from '../../components/fields/FormSelectField'
import { FormSelectFieldModel } from '../../components/fields/types/formSelectFieldModel'
import { PointDeVenteModel } from '../pointsDeVente/types/pointDeVenteModel'
import { fetchPointsdeVente } from '../pointsDeVente/api/pointsDeVenteApi'
import { orderEnum } from '../../components/table/enums/orderEnum'
import { CSVLink } from 'react-csv'

const TARGET_PDV_NAME = 'targetPDVSelector'

const Historique = () => {
	const { session } = useContext(AppContext)
	const [targetPDV, setTargetPDV] = useState<string>('')
	const [listPDV, setListPDV] = useState<PointDeVenteModel[]>([])

	const [openCancelPopin, setOpenCancelPopin] =
		useState<CancelPopinModel>(INITIAL_CANCEL_POPIN)
	const [openDetailsPopin, setOpenDetailsPopin] =
		useState<DetailsPopinModel>(INITIAL_DETAILS_POPIN)

	const targetPDVOptions: FormSelectFieldModel[] = useMemo(
		() =>
			listPDV.map((pdv) => ({
				label: pdv.Nom_du_PDV,
				value: pdv.Id_points_de_ventes
			})),
		[listPDV]
	)

	const headRows = useMemo(
		() => [
			{
				label: historiqueActivationTableLabelEnum.reference,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.reference
				)
			},
			{
				label: historiqueActivationTableLabelEnum.date,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.date
				)
			},
			{
				label: historiqueActivationTableLabelEnum.agent,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.agent
				)
			},
			{
				label: historiqueActivationTableLabelEnum.pointDeVente,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.pointDeVente
				)
			},
			{
				label: historiqueActivationTableLabelEnum.statut,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.statut
				)
			},
			{
				label: historiqueActivationTableLabelEnum.motif,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.motif
				)
			},
			{
				label: historiqueActivationTableLabelEnum.montant,
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.montant
				)
			},
			{
				label: '',
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.comment
				)
			},
			{
				label: '',
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.pdf
				)
			},
			{
				label: '',
				id: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.details
				)
			},
			{
				label: columnsEnum.modalsButtons,
				id: getKeyFromEnumValue<columnsEnum>(columnsEnum, columnsEnum.modalsButtons)
			}
		],
		[]
	)

	useQuery(['PointsdeVente', session.role], () => fetchPointsdeVente(true), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false,
		onSuccess: (data) => setListPDV(data)
	})

	const fetchActivations = useQuery(
		['historiqueActivations', targetPDV],
		() => fetchHistoriqueActivations(targetPDV),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			suspense: false
		}
	)

	const onCloseCancelDialog = useCallback(() => {
		setOpenCancelPopin(INITIAL_CANCEL_POPIN)
		fetchActivations.refetch()
	}, [])

	if (fetchActivations.isLoading) {
		return <h1>chargement ...</h1>
	}

	return (
		<Grid container item xs={12}>
			<CustomTable
				title="Historique des activations"
				tableContainerStyle={{ mt: 2 }}
				componentBeforeTable={
					<Grid container item xs={12} justifyContent="space-between">
						{(session.role !== sessionEnum.agentDePointDeVente ||
							(session.role === sessionEnum.agentDePointDeVente &&
								targetPDVOptions.length > 1)) && (
							<Box sx={{ mt: 2, width: '30%' }}>
								<FormSelectField
									options={targetPDVOptions}
									input={{
										value: targetPDV,
										name: TARGET_PDV_NAME,
										onChange: setTargetPDV,
										onBlur: () => {},
										onFocus: () => {}
									}}
									meta={{
										error: '',
										submitError: ''
									}}
								/>
							</Box>
						)}

						{session.role === sessionEnum.responsableReseau && (
							<Box
								sx={{
									mt: 2,
									width: '30%',
									textAlign: 'right',
									mr: 2,

									'& a': {
										color: 'texts.slateGrey'
									}
								}}
							>
								<CSVLink
									headers={headRows
										// On ne prend pas en compte les label vide (pdf / détail / boutons) sauf le commentaire
										.filter(
											(headRow) =>
												headRow.id ===
													getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
														historiqueActivationTableLabelEnum,
														historiqueActivationTableLabelEnum.comment
													) || headRow.label.length > 0
										)
										.map((headRow) => {
											// On est sur l'itération du commentaire
											if (headRow.label.length === 0) {
												return {
													label: 'Commentaire',
													key: getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
														historiqueActivationTableLabelEnum,
														historiqueActivationTableLabelEnum.comment
													)
												}
											}

											return {
												label: headRow.label,
												key: headRow.id
											}
										})}
									data={(fetchActivations.data ?? []).map((activation: ActivationModel) => ({
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.reference
										)]: activation.Id_demandes,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.date
										)]: activation.Date,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.agent
										)]: activation.Email,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.pointDeVente
										)]: activation.Nom_du_PDV,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.statut
										)]: activation.Statuts,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.motif
										)]: activation.Motif,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.montant
										)]: `${activation.montant} €`,
										[getKeyFromHistoriqueActivationTableLabelValue(
											historiqueActivationTableLabelEnum.comment
										)]: activation.Commentaire
									}))}
									filename="historique_activation.csv"
									separator=";"
								>
									Télécharger en CSV
								</CSVLink>
							</Box>
						)}
					</Grid>
				}
				headRows={headRows.filter((headTable) => {
					if (headTable.label === columnsEnum.modalsButtons) {
						return (
							session.role !== sessionEnum.superAdministrateur &&
							session.role !== sessionEnum.valideur
						)
					}

					return true
				})}
				rows={(fetchActivations.data ?? []).map((activation: ActivationModel) => ({
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.reference
					)]: activation.Id_demandes,
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.date
					)]: activation.Date,
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.agent
					)]: (
						<Box
							sx={{
								width: '100px',
								wordBreak: 'break-word'
							}}
						>
							{activation.Email}
						</Box>
					),
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.pointDeVente
					)]: activation.Nom_du_PDV,
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.statut
					)]: activation.Statuts,
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.motif
					)]: activation.Motif,
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.montant
					)]: `${activation.montant} €`,
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.comment
					)]: (
						<>
							{activation.Commentaire && activation.Commentaire.length > 0 && (
								<Tooltip title={activation.Commentaire}>
									<IconButton sx={{ p: 0 }}>
										<CommentIcon />
									</IconButton>
								</Tooltip>
							)}
						</>
					),
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.pdf
					)]: (
						<IconButton
							sx={{
								p: 0
							}}
							href={`/api/demandes/rapport/pdf?id=[${activation.Id_demandes}]`}
							target="_blank"
						>
							<PictureAsPdfIcon />
						</IconButton>
					),
					[getKeyFromHistoriqueActivationTableLabelValue(
						historiqueActivationTableLabelEnum.details
					)]: (
						<IconButton
							sx={{
								p: 0
							}}
							onClick={() => {
								setOpenDetailsPopin({
									open: true,
									refCartes: activation.refCartes
								})
							}}
						>
							<VisibilityOutlinedIcon />
						</IconButton>
					),
					[getKeyFromColumnValue(columnsEnum.modalsButtons)]: (
						<>
							{activation.Statuts === demandeStatusEnum.ETAT_CARTE_ATTENTE &&
								session.role !== sessionEnum.superAdministrateur &&
								session.role !== sessionEnum.valideur && (
								<TableModaleButton
									bgcolor="buttonRed"
									color="white"
									icon={<CloseIcon />}
									onClick={() => {
										setOpenCancelPopin({
											open: true,
											id: activation.Id_demandes
										})
									}}
								>
										Annuler
								</TableModaleButton>
							)}
						</>
					)
				}))}
				defaultOrder={orderEnum.desc}
				defaultOrderBy={getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
					historiqueActivationTableLabelEnum,
					historiqueActivationTableLabelEnum.date
				)}
			/>

			<PopinCancelActivation
				id={openCancelPopin.id}
				onCloseDialog={onCloseCancelDialog}
				open={openCancelPopin.open}
			/>

			<PopinDetailsActivation
				refCartes={openDetailsPopin.refCartes}
				onCloseDialog={() => setOpenDetailsPopin(INITIAL_DETAILS_POPIN)}
				open={openDetailsPopin.open}
			/>
		</Grid>
	)
}
export default Historique
