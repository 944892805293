import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './containers/App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme/theme'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppProvider } from './containers/context/AppContext'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'

// Création d'un client pour react-query
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
	// On encapsule l'application dans le react-query
	<QueryClientProvider client={queryClient}>
		<ThemeProvider theme={theme}>
			<IntlProvider
				locale="fr"
			>
				<AppProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</AppProvider>
			</IntlProvider>
		</ThemeProvider>
	</QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
