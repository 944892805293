import { Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchDistributeurs } from './api/enseignesApi'
import CustomTable from '../../components/table/Table'
import { EnseigneModel, INITIAL_ENSEIGNE } from './types/enseigneModel'
import TableModaleButton from '../../components/button/TableModaleButton'
import { Check, Close, Edit } from '@mui/icons-material'
import { enseignesTableLabelEnum } from './enums/enseignesEnum'
import PopinDistributeur from './components/PopinDistributeur'
import { AppContext } from '../../containers/context/AppContext'
import { colorSnackBarEnum } from '../../containers/enums/colorSnackBarEnum'
import PopinDesactivation from './components/PopinDesactivation'
import PopinReferenceProduit from './components/PopinReferenceProduit'
import { columnsEnum } from '../../components/table/enums/columnsEnum'
import { getKeyFromEnumValue } from '../../utils/utils'

const Enseignes = () => {
	const fetchEnseignes = useQuery('enseignes', fetchDistributeurs, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})
	const [openCreateEnseigne, setOpenCreateEnseigne] = useState<boolean>(false)
	const [activation, setActivation] = useState<boolean>(false)
	const [openDesactivation, setOpenDesactivation] = useState<boolean>(false)
	const [openReference, setOpenReference] = useState<boolean>(false)
	const [enseigneState, setEnseigneState] = useState<EnseigneModel>(INITIAL_ENSEIGNE)
	const { setSnackBar } = useContext(AppContext)

	if (fetchEnseignes.isLoading) {
		return <h1>chargement ...</h1>
	}

	const openModalCreateEnseigne = () => {
		setEnseigneState(INITIAL_ENSEIGNE)
		setOpenCreateEnseigne(true)
	}

	const closeModalCreateEnseigne = () => {
		setOpenCreateEnseigne(false)
		fetchEnseignes.refetch()
	}

	const openModalAlterEnseigne = (enseigne: EnseigneModel) => {
		setEnseigneState(enseigne)
		setOpenCreateEnseigne(true)
	}

	const openModalDesactivateEnseigne = (enseigne: EnseigneModel) => {
		if (enseigne.nombreDePointsDeVentes > 0) {
			setSnackBar({
				id: 'distributeur',
				message:
					'<b>Suppression impossible</b><br />Le distributeur Glady ne peut être supprimé car il comporte des points de ventes encore actifs.',
				open: true,
				color: 'backgrounds.buttonRed',
				icon: colorSnackBarEnum.red
			})
		} else {
			setActivation(false)
			setEnseigneState(enseigne)
			setOpenDesactivation(true)
		}
	}

	const openModalActivateEnseigne = (enseigne: EnseigneModel) => {
		setActivation(true)
		setEnseigneState(enseigne)
		setOpenDesactivation(true)
	}

	const closeModalDesactivateEnseigne = () => {
		setOpenDesactivation(false)
		fetchEnseignes.refetch()
	}

	const openModalAlterReferenceEnseigne = (enseigne: EnseigneModel) => {
		setEnseigneState(enseigne)
		setOpenReference(true)
	}

	const closeModalReferenceEnseigne = () => {
		setOpenReference(false)
		fetchEnseignes.refetch()
	}

	return (
		<Grid container item xs={12}>
			<CustomTable
				title="Gestion des distributeurs"
				buttonCreateLabel="Créer un distributeur"
				buttonCreateOnClick={openModalCreateEnseigne}
				headRows={[
					{
						label: enseignesTableLabelEnum.nom,
						id: getKeyFromEnumValue<enseignesTableLabelEnum>(
							enseignesTableLabelEnum,
							enseignesTableLabelEnum.nom
						)
					},
					{
						label: enseignesTableLabelEnum.logo,
						id: getKeyFromEnumValue<enseignesTableLabelEnum>(
							enseignesTableLabelEnum,
							enseignesTableLabelEnum.logo
						)
					},
					{
						label: enseignesTableLabelEnum.pointsDeVentes,
						id: getKeyFromEnumValue<enseignesTableLabelEnum>(
							enseignesTableLabelEnum,
							enseignesTableLabelEnum.pointsDeVentes
						)
					},
					{
						label: enseignesTableLabelEnum.nombreDeRefsProduit,
						id: getKeyFromEnumValue<enseignesTableLabelEnum>(
							enseignesTableLabelEnum,
							enseignesTableLabelEnum.nombreDeRefsProduit
						)
					},
					{
						label: columnsEnum.modalsButtons,
						id: getKeyFromEnumValue<columnsEnum>(columnsEnum, columnsEnum.modalsButtons)
					}
				]}
				rows={(fetchEnseignes.data ?? []).map((enseigne: EnseigneModel) => ({
					[getKeyFromEnumValue<enseignesTableLabelEnum>(
						enseignesTableLabelEnum,
						enseignesTableLabelEnum.nom
					)]: enseigne.Nom_enseigne,
					[getKeyFromEnumValue<enseignesTableLabelEnum>(
						enseignesTableLabelEnum,
						enseignesTableLabelEnum.logo
					)]: (
						<img
							style={{
								height: '3em',
								padding: '4px',
								backgroundColor: 'backgrounds.white',
								border: '1px solid #ddd',
								borderRadius: '4px'
							}}
							src={enseigne.Logo}
							alt={enseigne.Nom_enseigne}
						/>
					),
					[getKeyFromEnumValue<enseignesTableLabelEnum>(
						enseignesTableLabelEnum,
						enseignesTableLabelEnum.pointsDeVentes
					)]: enseigne.nombreDePointsDeVentes,
					[getKeyFromEnumValue<enseignesTableLabelEnum>(
						enseignesTableLabelEnum,
						enseignesTableLabelEnum.nombreDeRefsProduit
					)]: enseigne.nombreDeReferencesProduit,
					[getKeyFromEnumValue<columnsEnum>(columnsEnum, columnsEnum.modalsButtons)]: (
						<>
							<TableModaleButton
								bgcolor="buttonYellow"
								color="white"
								icon={<Edit />}
								onClick={() => openModalAlterEnseigne(enseigne)}
							>
								Modifier
							</TableModaleButton>
							{enseigne.Enseigne_active && (
								<TableModaleButton
									bgcolor="buttonRed"
									color="white"
									icon={<Close />}
									onClick={() => openModalDesactivateEnseigne(enseigne)}
								>
									Désactiver
								</TableModaleButton>
							)}
							{!enseigne.Enseigne_active && (
								<TableModaleButton
									bgcolor="green"
									color="white"
									icon={<Check />}
									onClick={() => openModalActivateEnseigne(enseigne)}
								>
									Réactiver
								</TableModaleButton>
							)}
							<TableModaleButton
								bgcolor="buttonBlue"
								color="white"
								icon={<Edit />}
								onClick={() => openModalAlterReferenceEnseigne(enseigne)}
							>
								Modifier Référence produit
							</TableModaleButton>
						</>
					)
				}))}
			/>
			<PopinDistributeur
				onCloseDialog={closeModalCreateEnseigne}
				open={openCreateEnseigne}
				enseigne={enseigneState}
			/>
			<PopinDesactivation
				onCloseDialog={closeModalDesactivateEnseigne}
				open={openDesactivation}
				enseigne={enseigneState}
				activation={activation}
			/>
			<PopinReferenceProduit
				onCloseDialog={closeModalReferenceEnseigne}
				open={openReference}
				enseigne={enseigneState}
			/>
		</Grid>
	)
}

export default Enseignes
