import React, { useContext, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { Field, Form } from 'react-final-form'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { submitModel } from '../../../types/registrationModel'
import { AppContext } from '../../../containers/context/AppContext'
import FormLoginField from '../../../components/fields/FormLoginField'
import { postFirstConnection } from '../api/loginAPI'

const RegistrationForm = () => {
	const [error, setError] = useState<string>('')
	const [src, setSrc] = useState<string>('/api/connexion/captcha?' + new Date().getTime())

	const { setSnackBar } = useContext(AppContext)
	const onSubmit = (values: submitModel) => {
		setError('')
		postFirstConnection(values).then((response: any) => {
			if (response.statut === 'OK') {
				setSnackBar({
					id: 'premiereConnexion',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
			} else {
				setError(response.messages[0])
				setSrc('/api/connexion/captcha?' + new Date().getTime())
			}
		})
	}
	return (
		<Form
			onSubmit={onSubmit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Grid container item xs={12}>
						<Grid
							sx={{
								bgcolor: 'backgrounds.white',
								width: '100%',
								padding: '10px'
							}}
							container
							item
							xs={12}
						>
							{error && (
								<Grid
									container
									item
									xs={12}
									sx={{
										color: 'texts.lightRed',
										bgcolor: 'backgrounds.pink',
										m: '0px 15px 10px 15px',
										p: '15px 35px 15px 15px',
										borderLeft: '3px solid'
									}}
								>
									<div dangerouslySetInnerHTML={{ __html: error }}></div>
								</Grid>
							)}
							<Grid
								container
								item
								xs={12}
								sx={{
									marginBottom: '15px'
								}}
							>
								<Box
									sx={{
										margin: 'auto'
									}}
								>
									Saisissez votre adresse email
								</Box>
								<Box
									sx={{
										color: 'texts.blue',
										margin: 'auto'
									}}
								>
									pour recevoir un mail de première connexion
								</Box>
							</Grid>
							<Grid container item xs={12}>
								<Field
									name="email"
									placeholder="Veuillez saisir votre adresse Email"
									component={FormLoginField}
									sx={{
										marginBottom: 10
									}}
								/>
							</Grid>
							<Grid
								container
								item
								xs={12}
								sx={{
									margin: '15px',
									display: 'block'
								}}
							>
								<img src={src} className="captcha" />
							</Grid>

							<Grid container item xs={12}>
								<Field
									name="captcha"
									placeholder="Veuillez saisir les caractères ci-dessus"
									component={FormLoginField}
								/>
							</Grid>
						</Grid>

						<Grid container item xs={12}>
							<Grid sx={{ p: '15px' }} container item xs={12} justifyContent="center">
								<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
									CRÉER MON COMPTE
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			)}
		/>
	)
}

export default RegistrationForm
