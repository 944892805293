import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormSelectFieldModel } from './types/formSelectFieldModel'
import { Autocomplete, Box, CircularProgress, TextField, useTheme } from '@mui/material'

interface AcceptingProps {
	options: FormSelectFieldModel[]
	label?: string
	fontSize?: string
	loading?: boolean
	multipleOptions?: boolean
}

// @todo essayer de retirer le type any
type FormSelectFieldProps = AcceptingProps & FieldRenderProps<any>

const FormSelectField: React.FC<FormSelectFieldProps> = ({
	meta: { touched, error },
	input,
	options,
	label,
	fontSize = '14px',
	loading = false,
	multipleOptions = false
}) => {
	const theme = useTheme()

	return (
		<>
			{label && (
				<span
					style={{
						width: '100%',
						paddingRight: 15,
						paddingTop: 7,
						fontSize,
						marginBottom: 5,
						marginLeft: 0,
						boxSizing: 'border-box',
						textAlign: 'left'
					}}
				>
					{label}
				</span>
			)}

			<Autocomplete
				id={`select-field-${input.name}`}
				sx={{
					width: '100%',
					height: 'fit-content',
					'& div': {
						height: '100%',
						padding: '0px !important',

						'& input': {
							height: '100%',
							padding: '0px 12px !important',
							color: 'texts.grey',
							fontSize: '14px'
						}
					},
					'& .MuiOutlinedInput-root': {
						minHeight: '32px',
						border: `1px solid ${
							theme.palette.texts[error && touched ? 'lightRed' : 'slateGrey']
						}`,

						'&:hover, &:focus': {
							borderColor: `${theme.palette.texts.blue} !important`
						}
					},
					'& fieldset': {
						borderColor: 'transparent !important'
					}
				}}
				value={
					multipleOptions
						? options.filter((option) => input.value.includes(option.value))
						: options.filter((option) => option.value === input.value)[0]
				}
				onChange={(event, newValue) => {
					if (!multipleOptions) {
						input.onChange((newValue as FormSelectFieldModel)?.value)
					} else {
						input.onChange(
							(newValue as FormSelectFieldModel[])?.map((newValueMapped) => newValueMapped.value)
						)
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} sx={{ mr: 4 }} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
				options={options}
				noOptionsText="Pas d'autres options"
				isOptionEqualToValue={(option, value) => option.value === value.value}
				loading={loading}
				loadingText="chargement ..."
				multiple={multipleOptions}
				disableCloseOnSelect={multipleOptions}
			/>

			{touched && error && (
				<Box
					sx={{
						color: 'texts.lightRed',
						fontSize: '12px',
						width: '100%',
						textAlign: 'center'
					}}
				>
					{error}
				</Box>
			)}
		</>
	)
}

export default FormSelectField
