import React, { useContext } from 'react'
import { AppContext } from '../../containers/context/AppContext'
import { sessionEnum } from '../../containers/enums/sessionEnum'
import FAQAdmin from './components/FAQAdmin'
import FAQCommon from './components/FAQCommon'
import { FAQProvider } from './context/FAQContext'

const FAQ = () => {
	const { session } = useContext(AppContext)

	if (session.role === sessionEnum.superAdministrateur) {
		return (
			<FAQProvider>
				<FAQAdmin />
			</FAQProvider>
		)
	}

	return <FAQCommon />
}

export default FAQ
