import React, { useState } from 'react'
import Dialog from '../../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../../components/dialog/types/dialogModel'
import { FAQModel } from '../../types/FAQModel'
import { postQuestion } from '../../api/FAQAdminAPI'
import FAQFormComponent from './FAQFormComponent'

const PopinAddQuestion: React.FC<DialogCommonProps> = ({ onCloseDialog, open }) => {
	const [loading, setLoading] = useState(false)

	const onSubmit = (values: FAQModel) => {
		setLoading(true)

		return postQuestion(values)
			.then((response) => onCloseDialog())
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages

					if (typeof errorData === 'object') {
						return Object.values(errorData)
							.map((errorValue) => `${errorValue} \n`)
							.join('')
					}
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<Dialog onCloseDialog={onCloseDialog} open={open} title="Ajouter une question">
			<FAQFormComponent
				onSubmit={onSubmit}
				loading={loading}
				onCloseDialog={onCloseDialog}
			/>
		</Dialog>
	)
}

export default PopinAddQuestion
