import Dialog from '../../../components/dialog/Dialog'
import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { EnseigneModel } from '../types/enseigneModel'
import { useQuery } from 'react-query'
import { fetchReferencesProduits } from '../api/referencesProduitApi'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableModaleButton from '../../../components/button/TableModaleButton'
import { Edit } from '@mui/icons-material'
import {
	INITIAL_REFERENCE_PRODUIT,
	ReferenceProduitModel
} from '../types/referenceProduitModel'
import PopinSuppressionReference from './PopinSuppressionReference'
import PopinSaisieMontant from './PopinSaisieMontant'
import PopinModificationMontant from './PopinModificationMontant'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	enseigne: EnseigneModel
}

type PopinDesactivationProps = AcceptingProps

const PopinReferenceProduit: React.FC<PopinDesactivationProps> = ({
	onCloseDialog,
	open,
	enseigne
}) => {
	const referencesProduit = useQuery(
		['referencesProduit', enseigne.Id_enseignes],
		() => fetchReferencesProduits(enseigne.Id_enseignes),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			suspense: false
		}
	)

	const [openSuppression, setOpenSuppression] = useState<boolean>(false)
	const [openSaisie, setOpenSaisie] = useState<boolean>(false)
	const [openModification, setOpenModification] = useState<boolean>(false)
	const [refProduit, setRefProduit] = useState<ReferenceProduitModel>(
		INITIAL_REFERENCE_PRODUIT
	)

	const openModalSuppressionReference = (refProduit: ReferenceProduitModel) => {
		setRefProduit(refProduit)
		setOpenSuppression(true)
	}

	const closeModalSuppressionReference = () => {
		setOpenSuppression(false)
		referencesProduit.refetch()
	}

	const openModalSaisieMontant = (refProduit: ReferenceProduitModel) => {
		setRefProduit(refProduit)
		setOpenSaisie(true)
	}

	const closeModalSaisieReference = () => {
		setOpenSaisie(false)
		referencesProduit.refetch()
	}

	const openModalModificationMontant = (refProduit: ReferenceProduitModel) => {
		setRefProduit(refProduit)
		setOpenModification(true)
	}

	const closeModalModificationReference = () => {
		setOpenModification(false)
		referencesProduit.refetch()
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title={enseigne.Nom_enseigne}
			backgroundColor="#f0ad4e"
		>
			<>
				<Grid sx={{ p: '20px 24px' }} container item xs={12}>
					<TableContainer component={Paper}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Référence produits</TableCell>
									<TableCell>Montant minimum</TableCell>
									<TableCell>Montant maximum</TableCell>
									<TableCell>Modification Montant</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{referencesProduit.data &&
									referencesProduit.data.map((row) => (
										<TableRow
											key={row.Ref_produit}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{row.Ref_produit}
											</TableCell>
											<TableCell align="right">{row.Montant_min}</TableCell>
											<TableCell align="right">{row.Montant_max}</TableCell>
											<TableCell
												sx={{
													width: '180px',
													textAlign: 'center'
												}}
											>
												<TableModaleButton
													bgcolor="darkGrey"
													color="white"
													icon={<Edit />}
													onClick={() => openModalModificationMontant(row)}
												>
													Modifier Montants
												</TableModaleButton>
												<br />
												<TableModaleButton
													bgcolor="buttonRed"
													color="white"
													icon={<Edit />}
													onClick={() => openModalSuppressionReference(row)}
												>
													Supprimer Montants
												</TableModaleButton>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid
					sx={{
						p: '15px',
						bgcolor: 'backgrounds.blue'
					}}
					container
					item
					xs={12}
					justifyContent="center"
				>
					<Grid container item xs={6} justifyContent="left">
						<Button
							bgcolor="buttonRed"
							color="white"
							type={buttonTypesEnum.reset}
							onClick={onCloseDialog}
						>
							ANNULER
						</Button>
					</Grid>
					<Grid container item xs={6} justifyContent="right">
						<Button
							bgcolor="buttonYellow"
							color="white"
							type={buttonTypesEnum.submit}
							onClick={() => openModalSaisieMontant(INITIAL_REFERENCE_PRODUIT)}
						>
							CRÉER
						</Button>
					</Grid>
				</Grid>
				<PopinSuppressionReference
					onCloseDialog={closeModalSuppressionReference}
					open={openSuppression}
					refProduit={refProduit}
				/>
				<PopinSaisieMontant
					onCloseDialog={closeModalSaisieReference}
					open={openSaisie}
					montant={refProduit}
					enseigneId={enseigne.Id_enseignes}
				/>
				<PopinModificationMontant
					onCloseDialog={closeModalModificationReference}
					open={openModification}
					montant={refProduit}
					enseigneId={enseigne.Id_enseignes}
				/>
			</>
		</Dialog>
	)
}

export default PopinReferenceProduit
