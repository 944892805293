import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'

export interface SnackBarModel {
	id: string
	message: string
	open: boolean
	color?: string
	icon?: colorSnackBarEnum
}

export const INITIAL_SNACKBAR: SnackBarModel = {
	id: '',
	message: '',
	open: false
}
