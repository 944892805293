import { cancelActivationFieldsEnum } from '../enums/cancelActivationEnum'

export interface ActivationRefCartesModel {
	numeroLogistique: string
	montant: number
	etatDeLaCarte: string
	raisonAnomalie: string
}

export interface ActivationModel {
	Commentaire: string
	Date: string
	Email: string
	Id_demandes: string
	Id_user: string
	Motif: string
	Nom_du_PDV: string
	Nom_user: string
	Prenom_user: string
	Statuts: string
	Validation: string
	nombreDeCarteEnSatutDAnomalie: string
	montant: number
	refCartes: ActivationRefCartesModel[]
}

export interface ActivationFormModel {
	[cancelActivationFieldsEnum.reason]: string
}

export interface CancelPopinModel {
	id: string
	open: boolean
}

export const INITIAL_CANCEL_POPIN: CancelPopinModel = {
	id: '',
	open: false
}

export interface DetailsPopinModel {
	refCartes: ActivationRefCartesModel[]
	open: boolean
}

export const INITIAL_DETAILS_POPIN: DetailsPopinModel = {
	refCartes: [],
	open: false
}
