import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchUtilisateurs, getDemandesByPDVUser } from './api/utilisateursApi'
import { Grid } from '@mui/material'
import CustomTable from '../../components/table/Table'
import TableModaleButton from '../../components/button/TableModaleButton'
import { Check, Close, Edit } from '@mui/icons-material'
import {
	getKeyFromUtilisateursTableLabelValue,
	utilisateursTableLabelEnum
} from './enums/utilisateursEnum'
import { INITIAL_UTILISATEUR, UtilisateurModel } from './types/utilisateurModel'
import { AppContext } from '../../containers/context/AppContext'
import PopinDesactivationUtilisateur from './components/PopinDesactivationUtilisateur'
import { colorSnackBarEnum } from '../../containers/enums/colorSnackBarEnum'
import { genericError } from '../../utils/fetchFactory/enums/genericErrorEnum'
import PopinUtilisateur from './components/PopinUtilisateur'

const Utilisateurs = () => {
	const fetchUsers = useQuery('utilisateurs', fetchUtilisateurs, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})
	const [activation, setActivation] = useState<boolean>(false)
	const [openDesactivation, setOpenDesactivation] = useState<boolean>(false)
	const [utilisateur, setUtilisateur] = useState<UtilisateurModel>(INITIAL_UTILISATEUR)
	const { setSnackBar } = useContext(AppContext)

	const [openPopinUtilisateur, setOpenPopinUtilisateur] = useState<boolean>(false)

	if (fetchUsers.isLoading) {
		return <h1>chargement ...</h1>
	}

	const openModalUtilisateur = (utilisateur?: UtilisateurModel) => {
		setUtilisateur(utilisateur ?? INITIAL_UTILISATEUR)
		setOpenPopinUtilisateur(true)
	}

	const closeModalUtilisateur = () => {
		setOpenPopinUtilisateur(false)
		fetchUsers.refetch()
	}

	const openModalDesactivateUtilisateur = (
		user: UtilisateurModel,
		activation: boolean
	) => {
		if (activation) {
			setUtilisateur(user)
			setActivation(activation)
			setOpenDesactivation(true)
		} else {
			getDemandesByPDVUser(user.Id_user)
				.then((response: any) => {
					if (response.length > 0) {
						setSnackBar({
							id: 'userDemandes',
							message:
								'Impossible de désactiver l\'utilisateur ' +
								user.Nom_user.toUpperCase() +
								' ' +
								user.Prenom_user +
								' car il a émis au moins une demande encore en attente de traitement',
							open: true,
							color: 'backgrounds.buttonRed',
							icon: colorSnackBarEnum.red
						})
					} else {
						setUtilisateur(user)
						setActivation(activation)
						setOpenDesactivation(true)
					}
				})
				.catch((error) => {
					console.error(error)
					setSnackBar({
						id: 'userDemandes',
						message: genericError.error500,
						open: true,
						color: 'backgrounds.buttonRed',
						icon: colorSnackBarEnum.red
					})
				})
		}
	}

	const closeModalDesactivate = () => {
		setOpenDesactivation(false)
		fetchUsers.refetch()
	}

	return (
		<Grid container item xs={12}>
			<CustomTable
				title="Gestion des utilisateurs"
				buttonCreateLabel="Créer un utilisateur"
				buttonCreateOnClick={() => openModalUtilisateur()}
				headRows={[
					{
						label: utilisateursTableLabelEnum.nom,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.nom)
					},
					{
						label: utilisateursTableLabelEnum.prenom,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.prenom)
					},
					{
						label: utilisateursTableLabelEnum.email,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.email)
					},
					{
						label: utilisateursTableLabelEnum.fonction,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.fonction)
					},
					{
						label: utilisateursTableLabelEnum.pointDeVente,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.pointDeVente)
					},
					{
						label: utilisateursTableLabelEnum.distributeur,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.distributeur)
					},
					{
						label: utilisateursTableLabelEnum.modalsButtons,
						id: getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.modalsButtons)
					}
				]}
				rows={(fetchUsers.data ?? []).map((utilisateur: UtilisateurModel) => ({
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.nom)]:
						utilisateur.Nom_user,
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.prenom)]:
						utilisateur.Prenom_user,
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.email)]:
						utilisateur.Email,
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.fonction)]:
						utilisateur.Nom,
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.pointDeVente)]:
						utilisateur.Nom_du_PDV,
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.distributeur)]:
						utilisateur.Nom_enseigne,
					[getKeyFromUtilisateursTableLabelValue(utilisateursTableLabelEnum.modalsButtons)]: (
						<>
							<TableModaleButton
								bgcolor="buttonYellow"
								color="white"
								icon={<Edit />}
								onClick={() => openModalUtilisateur(utilisateur)}
							>
								Modifier
							</TableModaleButton>
							{utilisateur.Active && (
								<TableModaleButton
									bgcolor="buttonRed"
									color="white"
									icon={<Close />}
									onClick={() => openModalDesactivateUtilisateur(utilisateur, false)}
								>
									Désactiver
								</TableModaleButton>
							)}
							{!utilisateur.Active && (
								<TableModaleButton
									bgcolor="green"
									color="white"
									icon={<Check />}
									onClick={() => openModalDesactivateUtilisateur(utilisateur, true)}
								>
									Réactiver
								</TableModaleButton>
							)}
						</>
					)
				}))}
			/>
			<PopinDesactivationUtilisateur
				onCloseDialog={closeModalDesactivate}
				open={openDesactivation}
				activation={activation}
				user={utilisateur}
			/>
			<PopinUtilisateur
				onCloseDialog={closeModalUtilisateur}
				open={openPopinUtilisateur}
				user={utilisateur}
			/>
		</Grid>
	)
}
export default Utilisateurs
