import React, { ChangeEventHandler } from 'react'
import { InputBase } from '@mui/material'
import { TextFieldAcceptingProps } from '../../fields/FormTextField'

interface AcceptingProps {
	value: string
	name: string
	onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

type SearchBarProps = AcceptingProps & TextFieldAcceptingProps

const TableSearchBar: React.FC<SearchBarProps> = ({
	id,
	placeholder,
	value,
	onChange,
	name
}) => {
	return (
		<InputBase
			color="primary"
			sx={{
				'& input': {
					borderWidth: '1px',
					borderStyle: 'solid',
					borderColor: 'texts.slateGrey',
					borderRadius: '2px',
					px: '12px',
					py: '6px',
					height: 16,
					'&::-webkit-input-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px'
					},
					'&::-moz-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px'
					},
					'&:moz-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px'
					},
					'&:-ms-input-placeholder': {
						fontStyle: 'italic',
						fontSize: '12px',
						lineHeight: '20px'
					}
				},
				'& input:focus': {
					borderColor: '#22beef'
				}
			}}
			id={id}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			name={name}
		/>
	)
}

export default TableSearchBar
