import axios from 'axios'
import { PointDeVenteModel } from '../types/pointDeVenteModel'

export const fetchPointsdeVente = (withDemandes: boolean): Promise<PointDeVenteModel[]> =>
	axios(`/api/points-de-ventes${withDemandes ? '?withDemandes=true' : ''}`)
		.then((res) => res.data)
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data)
				console.log(error.response.status)
				console.log(error.response.headers)
			} else if (error.request) {
				console.log(error.request)
			} else {
				console.log('Error', error.message)
			}
			console.log(error.config)
		})

export const createPDV = (url: string, data: PointDeVenteModel) =>
	axios.post(url, data).then((response) => response.data)
export const updatePDV = (url: string, data: PointDeVenteModel) =>
	axios.put(url, data).then((response) => response.data)
export const editStatusPDV = (pdv: string, activate: boolean) =>
	axios
		.put(`/api/points-de-ventes/${pdv}/${activate ? 'reactiver' : 'desactiver'}`)
		.then((response) => response.data)
