import Dialog from '../../../components/dialog/Dialog'
import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { AppContext } from '../../../containers/context/AppContext'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import { ReferenceProduitModel } from '../types/referenceProduitModel'
import { deleteRefCard } from '../api/referencesProduitApi'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	refProduit: ReferenceProduitModel
}

type PopinSuppressionReferenceProps = AcceptingProps

const PopinSuppressionReference: React.FC<PopinSuppressionReferenceProps> = ({
	onCloseDialog,
	open,
	refProduit
}) => {
	const { setSnackBar } = useContext(AppContext)

	const onSubmit = () => {
		deleteRefCard(refProduit.Ref_produit, refProduit.Enseignes).then((response: any) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'distributeur',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'distributeur',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
			}
			onCloseDialog()
		})
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title="Supprimer les montants pour un produit"
		>
			<Grid sx={{ p: '20px 24px' }} container item xs={12}>
				Voulez-vous vraiment supprimer les montants pour le produit ?
			</Grid>

			<Grid
				sx={{
					p: '15px',
					bgcolor: 'backgrounds.blue'
				}}
				container
				item
				xs={12}
				justifyContent="center"
			>
				<Grid container item xs={6} justifyContent="left">
					<Button
						bgcolor="buttonRed"
						color="white"
						type={buttonTypesEnum.reset}
						onClick={onCloseDialog}
					>
						RETOUR
					</Button>
				</Grid>
				<Grid container item xs={6} justifyContent="right">
					<Button
						bgcolor="green"
						color="white"
						type={buttonTypesEnum.submit}
						onClick={onSubmit}
					>
						SUPPRIMER
					</Button>
				</Grid>
			</Grid>
		</Dialog>
	)
}

export default PopinSuppressionReference
