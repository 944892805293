import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import Link from '../../../components/link/Link'
import { AppContext } from '../../../containers/context/AppContext'
import { Field, Form } from 'react-final-form'
import { ConnexionResponseModel, submitModel } from '../../../types/logInModel'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { useNavigate } from 'react-router-dom'
import { linksEnum } from '../../../containers/enums/linksEnum'
import FormLoginField from '../../../components/fields/FormLoginField'
import { sessionEnum } from '../../../containers/enums/sessionEnum'
import { postConnexion } from '../api/loginAPI'
import PopinDoubleAuth from './PopinDoubleAuth'

interface AcceptingProps {
	setHasForgotPassword: (hasForgotPassword: boolean) => void
}

type LoginFormProps = AcceptingProps

const LoginForm: React.FC<LoginFormProps> = ({ setHasForgotPassword }) => {
	const { configurations, setSession } = useContext(AppContext)
	const [linkAide, setLinkAide] = useState<string>('')
	const [linkContact, setLinkContact] = useState<string>('')
	const [error, setError] = useState<string>('')
	const navigate = useNavigate()
	const [idUserPopinDoubleAuth, setIdUserPopinDoubleAuth] = useState<number | null>(null)

	const onSubmit = (values: submitModel) => {
		setError('')
		postConnexion(values).then((loginResponse: ConnexionResponseModel) => {
			if (loginResponse.statut === 'OK') {
				// Check si doubleAuth nécessaire.
				if (loginResponse.doubleAuth === true) {
					// Affichage popin code double auth.
					setIdUserPopinDoubleAuth(loginResponse.user.id)
				} else {
					setSession(loginResponse.user)
					switch (loginResponse.user.role) {
						case sessionEnum.superAdministrateur:
							navigate(linksEnum.enseignes)
							break
						case sessionEnum.valideur:
							navigate(linksEnum.historique)
							break
						case sessionEnum.agentDePointDeVente:
							navigate(linksEnum.saisie)
							break
						case sessionEnum.responsableReseau:
							navigate(linksEnum.historique)
							break
						case sessionEnum.responsablePointDeVente:
							navigate(linksEnum.pointsDeVentes)
							break
					}
				}
			} else {
				setError(loginResponse.messages[0])
			}
		})
	}

	useEffect(() => {
		setLinkAide(configurations.links.help)
		setLinkContact(configurations.links.email)
	}, [configurations])

	const closeModalDoubleAuth = () => {
		setIdUserPopinDoubleAuth(null)
	}

	return (
		<>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, values }) => (
					<>
						<form onSubmit={handleSubmit}>
							<Grid container item xs={12}>
								<Grid
									sx={{
										bgcolor: 'backgrounds.white',
										width: '100%',
										paddingTop: '10px',
										paddingBottom: '10px'
									}}
									container
									item
									xs={12}
								>
									{error && (
										<Grid
											container
											item
											xs={12}
											sx={{
												color: 'texts.lightRed',
												bgcolor: 'backgrounds.pink',
												m: '0px 15px 10px 15px',
												p: '15px 35px 15px 15px',
												borderLeft: '3px solid'
											}}
										>
											{error}
										</Grid>
									)}
									<Grid container item xs={12}>
										<Field
											name="email"
											label="Email"
											placeholder="Veuillez saisir votre adresse Email"
											component={FormLoginField}
											sx={{
												marginBottom: 10
											}}
										/>
									</Grid>

									<Grid container item xs={12}>
										<Field
											name="password"
											type="password"
											label="Mot de passe"
											placeholder="Veuillez saisir votre mot de passe"
											component={FormLoginField}
										/>
									</Grid>
									<Grid
										container
										item
										xs={12}
										sx={{
											paddingTop: '10px',
											display: 'block'
										}}
									>
										<Link
											onClick={() => setHasForgotPassword(true)}
											href=""
											bold={true}
											hover={false}
											color="grey"
										>
											Mot de passe oublié ?
										</Link>
									</Grid>
								</Grid>

								<Grid container item xs={12}>
									<Grid sx={{ p: '15px' }} container item xs={12} justifyContent="center">
										<Button bgcolor="green" color="white" type={buttonTypesEnum.submit}>
											SE CONNECTER
										</Button>
									</Grid>

									<Grid sx={{ p: '15px' }} container item xs={12}>
										<Grid item xs={6}>
											<Link href={linkAide} target="_blank" bold={false} hover={true}>
												Aide
											</Link>
										</Grid>
										<Grid item xs={6}>
											<Link href={`mailto:${linkContact}`} target="_blank" bold={false} hover={true}>
												Contact
											</Link>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
						<PopinDoubleAuth
							onCloseDialog={closeModalDoubleAuth}
							open={idUserPopinDoubleAuth !== null}
							userId={idUserPopinDoubleAuth}
							onSubmitLogin={() => onSubmit(values)}
						/>
					</>
				)}
			/>
		</>
	)
}

export default LoginForm
