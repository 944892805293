import React from 'react'
import { ButtonType } from './types/buttonModel'
import { styled } from '@mui/material'
import ButtonUnstyled from '@mui/base/ButtonUnstyled'
import { buttonTypesEnum } from './enums/buttonTypesEnum'

const CustomButton = styled('button')`
	cursor: pointer;
	border: none;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	padding: 6px 12px;
`

const Button: React.FC<ButtonType> = ({
	children,
	type = buttonTypesEnum.button,
	bgcolor = 'darkBlue',
	color = 'white',
	onClick,
	disabled = false,
	opacity = 1,
	sx
}) => (
	<ButtonUnstyled
		type={type}
		sx={[
			sx,
			{
				bgcolor: `backgrounds.${bgcolor}`,
				color: `texts.${color}`,
				opacity: disabled ? '0.65' : `${opacity}`,
				cursor: disabled ? 'default' : 'pointer'
			}
		]}
		component={CustomButton}
		onClick={onClick}
		disabled={disabled}
	>
		{children}
	</ButtonUnstyled>
)

export default Button
