import React, { useContext, useState } from 'react'
import { Box, Grid } from '@mui/material'
import Button from '../../../../../components/button/Button'
import { buttonTypesEnum } from '../../../../../components/button/enums/buttonTypesEnum'
import Loader from '../../../../../components/loader/Loader'
import { CategoryModel } from '../../../types/FAQModel'
import { deleteCategory } from '../../../api/FAQAdminAPI'
import { FAQContext } from '../../../context/FAQContext'

interface AcceptingProps {
	category: CategoryModel
	onCancel: () => void
}

type CategorySuppressionProps = AcceptingProps

const CategorySuppression: React.FC<CategorySuppressionProps> = ({
	category,
	onCancel
}) => {
	const [loading, setLoading] = useState(false)
	const { setCategories } = useContext(FAQContext)

	const onDeleteCategory = () => {
		setLoading(true)

		return deleteCategory(category.id)
			.then(() => {
				// Suppression de la catégorie dans la liste
				setCategories((categories) => {
					// On récupère l'index de la catégorie dans la liste
					const index = categories.findIndex(
						(categoryState) => categoryState.id === category.id
					)

					// On supprime l'ancienne catégorie de la liste
					return [...categories.slice(0, index), ...categories.slice(index + 1)]
				})

				onCancel()
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages

					if (typeof errorData === 'object') {
						return Object.values(errorData)
							.map((errorValue) => `${errorValue} \n`)
							.join('')
					}
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<>
			<Grid sx={{ p: '20px 24px' }} container item xs={12}>
				<Box
					component="h1"
					sx={{
						mt: 0,
						color: 'texts.grey',
						fontSize: '20px'
					}}
				>
					Êtes-vous sûr de vouloir supprimer la catégorie <em>{category.label}</em> ? <br />
					<br />
					En supprimant la catégorie, vous supprimerez les questions liées à celle-ci.
				</Box>
			</Grid>

			<Grid
				sx={{
					p: '15px',
					bgcolor: 'backgrounds.buttonRedContainer'
				}}
				container
				item
				xs={12}
				justifyContent="center"
			>
				<Grid container item xs={6} justifyContent="left">
					<Button
						bgcolor="buttonRed"
						color="white"
						type={buttonTypesEnum.reset}
						onClick={onCancel}
					>
						ANNULER
					</Button>
				</Grid>
				<Grid container item xs={6} justifyContent="right">
					{loading ? (
						<Loader size={40} />
					) : (
						<Button
							bgcolor="green"
							color="white"
							type={buttonTypesEnum.button}
							onClick={onDeleteCategory}
						>
							SUPPRIMER
						</Button>
					)}
				</Grid>
			</Grid>
		</>
	)
}

export default CategorySuppression
