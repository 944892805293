import React, { useContext, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { useQuery } from 'react-query'
import { getCategories, getQuestions } from '../api/FAQAdminAPI'
import CustomTable from '../../../components/table/Table'
import { faqTableHeadEnum, getKeyFromFaqTableHead } from '../enums/FAQEnum'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import PopinAddQuestion from './modals/PopinAddQuestion'
import {
	columnsEnum,
	getKeyFromColumnValue
} from '../../../components/table/enums/columnsEnum'
import TableModaleButton from '../../../components/button/TableModaleButton'
import { Close, Edit } from '@mui/icons-material'
import PopinEditQuestion from './modals/PopinEditQuestion'
import {
	FAQAdminModel,
	INITIAL_POPIN_WITH_ELEMENT,
	PopinWithElementModel
} from '../types/FAQAdminModel'
import PopinSuppressionQuestion from './modals/PopinSuppressionQuestion'
import { popinFAQFormFieldEnum } from '../enums/popinAddQuestionEnum'
import { FAQContext } from '../context/FAQContext'
import PopinCategoryFAQ from './modals/PopinCategoryFAQ'
import { sessionEnum } from '../../../containers/enums/sessionEnum'

const FAQAdmin = () => {
	const [openAddPopin, setOpenAddPopin] = useState(false)
	const [openEditPopin, setOpenEditPopin] = useState<PopinWithElementModel>(
		INITIAL_POPIN_WITH_ELEMENT
	)
	const [openSuppressionPopin, setOpenSuppressionPopin] = useState<PopinWithElementModel>(
		INITIAL_POPIN_WITH_ELEMENT
	)
	const [openCategoryPopin, setOpenCategoryPopin] = useState(false)
	const { setCategories, categories } = useContext(FAQContext)

	const tableHeads = useMemo(
		() => [
			{
				label: faqTableHeadEnum.question,
				id: getKeyFromFaqTableHead(faqTableHeadEnum.question)
			},
			{
				label: faqTableHeadEnum.answer,
				id: getKeyFromFaqTableHead(faqTableHeadEnum.answer)
			},
			{
				label: faqTableHeadEnum.category,
				id: getKeyFromFaqTableHead(faqTableHeadEnum.category)
			},
			{
				label: faqTableHeadEnum.position,
				id: getKeyFromFaqTableHead(faqTableHeadEnum.position)
			},
			{
				label: columnsEnum.modalsButtons,
				id: getKeyFromColumnValue(columnsEnum.modalsButtons)
			}
		],
		[]
	)

	// Récupération des catégories
	const categoryResult = useQuery('categories', getCategories, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false,
		onSuccess: (data) => setCategories(data)
	})

	// Récupération des FAQ grouper par rôle d'utilisateur
	const FAQAdminResult = useQuery<FAQAdminModel[]>('faqAdmin', getQuestions, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})

	if (FAQAdminResult.isFetching || categoryResult.isFetching) {
		return <h1>chargement ...</h1>
	}

	const onCloseDialog = () => {
		setOpenAddPopin(false)
		setOpenEditPopin(INITIAL_POPIN_WITH_ELEMENT)
		setOpenSuppressionPopin(INITIAL_POPIN_WITH_ELEMENT)
		setOpenCategoryPopin(false)
		FAQAdminResult.refetch()
	}

	return (
		<Grid container item xs={12}>
			<Grid
				container
				item
				xs={12}
				sx={{
					marginTop: 2
				}}
			>
				{categories.length > 0 && (
					<Button
						bgcolor="green"
						color="white"
						type={buttonTypesEnum.button}
						onClick={() => setOpenAddPopin(true)}
						sx={{
							mr: 2
						}}
					>
						AJOUTER UNE QUESTION
					</Button>
				)}

				<Button
					bgcolor="blue"
					color="white"
					type={buttonTypesEnum.button}
					onClick={() => setOpenCategoryPopin(true)}
				>
					GÉRER LES CATÉGORIES
				</Button>
			</Grid>

			{(!categoryResult.data || categoryResult.data.length === 0) && (
				<p>Vous ne pouvez pas ajouter ou modifier une FAQ sans catégorie</p>
			)}

			{(FAQAdminResult.data ?? []).map((result) => (
				<CustomTable
					key={result.profileType}
					title={`Gestion des FAQ pour le rôle ${result.profileType}`}
					headRows={tableHeads}
					rows={result.questions.map((question) =>
						tableHeads.reduce((acc, tableHead) => {
							if (tableHead.id === getKeyFromColumnValue(columnsEnum.modalsButtons)) {
								return {
									...acc,
									[tableHead.id]: (
										<>
											{categoryResult.data && categoryResult.data.length > 0 && (
												<TableModaleButton
													bgcolor="buttonYellow"
													color="white"
													icon={<Edit />}
													onClick={() =>
														setOpenEditPopin({
															question: {
																...question,
																[popinFAQFormFieldEnum.relatedRole]:
																	result.profileType || sessionEnum.valideur
															},
															open: true
														})
													}
												>
													Modifier
												</TableModaleButton>
											)}

											<TableModaleButton
												bgcolor="buttonRed"
												color="white"
												icon={<Close />}
												onClick={() =>
													setOpenSuppressionPopin({
														question: question,
														open: true
													})
												}
											>
												Supprimer
											</TableModaleButton>
										</>
									)
								}
							} else {
								return {
									...acc,
									[tableHead.id]: question[tableHead.id as keyof typeof question]
								}
							}
						}, {})
					)}
					defaultOrderBy={getKeyFromFaqTableHead(faqTableHeadEnum.position)}
				/>
			))}

			<PopinAddQuestion onCloseDialog={onCloseDialog} open={openAddPopin} />

			<PopinEditQuestion
				onCloseDialog={onCloseDialog}
				open={openEditPopin.open && openEditPopin.question.id >= 0}
				question={openEditPopin.question}
			/>

			<PopinSuppressionQuestion
				onCloseDialog={onCloseDialog}
				open={openSuppressionPopin.open && openSuppressionPopin.question.id >= 0}
				question={openSuppressionPopin.question}
			/>

			<PopinCategoryFAQ onCloseDialog={onCloseDialog} open={openCategoryPopin} />
		</Grid>
	)
}

export default FAQAdmin
