export interface PointDeVenteModel {
	Description_du_PDV: string
	Enseigne_active: boolean
	Ezp_id: string
	Ezp_mdp: string
	Id_enseignes: number
	Id_points_de_ventes: number
	Identifiant_technique_transmitterid: string
	Logo: string
	Montant_max: number
	Montant_min: number
	Nom_du_PDV: string
	Nom_enseigne: string
	Nombre_max_cartes: number
	Nombre_saisie: number
	Pdv_active: boolean
	Type: string
	nombreDemandes: number
	nombreDemandesAttente: number
}

export const INITIAL_POINT_DE_VENTE: PointDeVenteModel = {
	Description_du_PDV: '',
	Enseigne_active: true,
	Ezp_id: '',
	Ezp_mdp: '',
	Id_enseignes: 0,
	Id_points_de_ventes: 0,
	Identifiant_technique_transmitterid: '',
	Logo: '',
	Montant_max: 0,
	Montant_min: 0,
	Nom_du_PDV: '',
	Nom_enseigne: '',
	Nombre_max_cartes: 0,
	Nombre_saisie: 0,
	Pdv_active: true,
	Type: '',
	nombreDemandes: 0,
	nombreDemandesAttente: 0
}
