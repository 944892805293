import axios from 'axios'
import { EnseigneModel } from '../types/enseigneModel'

export const fetchDistributeurs = (): Promise<EnseigneModel[]> =>
	axios('/api/distributeurs')
		.then((res) => res.data)
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data)
				console.log(error.response.status)
				console.log(error.response.headers)
			} else if (error.request) {
				console.log(error.request)
			} else {
				console.log('Error', error.message)
			}
			console.log(error.config)
		})

export const statusDistributeur = (enseigne: string, activation: boolean) =>
	axios
		.put(`/api/distributeurs/${enseigne}/${activation ? 'reactiver' : 'desactiver'}`)
		.then((response) => response.data)
export const addDistributeur = (url: string, data: FormData) =>
	axios.post(url, data).then((response) => response.data)
