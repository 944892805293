import Dialog from '../../../components/dialog/Dialog'
import React, { useContext, useMemo, useState } from 'react'
import FormTextField from '../../../components/fields/FormTextField'
import { Field, Form } from 'react-final-form'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { AppContext } from '../../../containers/context/AppContext'
import { PointDeVenteModel } from '../types/pointDeVenteModel'
import { useQuery } from 'react-query'
import { fetchDistributeurs } from '../../enseignes/api/enseignesApi'
import FormSelectField from '../../../components/fields/FormSelectField'
import { FormSelectFieldModel } from '../../../components/fields/types/formSelectFieldModel'
import { typePointDeVenteEnum } from '../enums/typePointDeVenteEnum'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import { sessionEnum } from '../../../containers/enums/sessionEnum'
import { createPDV, updatePDV } from '../api/pointsDeVenteApi'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	pointDeVente: PointDeVenteModel
	creation: boolean
}

type PopinPointDeVenteProps = AcceptingProps

const PopinPointDeVente: React.FC<PopinPointDeVenteProps> = ({
	onCloseDialog,
	open,
	pointDeVente,
	creation
}) => {
	const fetchDistributeur = useQuery('Distributeurs', fetchDistributeurs, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})
	const [disabled, setDisabled] = useState<boolean>(true)

	const { setSnackBar, session } = useContext(AppContext)

	const validate = (values: PointDeVenteModel) => {
		if (session.role === sessionEnum.responsableReseau && session.Id_enseignes) {
			values.Id_enseignes = session.Id_enseignes
		}
		const errors: any = {}
		if (!values.Nom_du_PDV) {
			errors.Nom_du_PDV = 'Le nom du point de vente est obligatoire'
		}
		if (!values.Id_enseignes) {
			errors.Id_enseignes = 'Veuillez sélectionner un distributeur'
		}
		if (!values.Identifiant_technique_transmitterid) {
			errors.Identifiant_technique_transmitterid =
				'L\'identifiant technique du point de vente est obligatoire'
		}
		setDisabled(Object.keys(errors).length > 0)

		console.log('errors', errors)
		return errors
	}

	const onSubmit = (values: PointDeVenteModel) => {
		if (session.role === sessionEnum.responsableReseau && session.Id_enseignes) {
			values.Id_enseignes = session.Id_enseignes
		}
		let url = '/api/points-de-ventes'
		if (!creation) {
			url += '/' + values.Id_points_de_ventes
		}

		const fetch = creation ? createPDV(url, values) : updatePDV(url, values)

		fetch.then((response: any) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'pointsDeVentes',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'pointsDeVentes',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				onCloseDialog()
			}
		})
	}

	const categoryOptions: FormSelectFieldModel[] = useMemo(
		() =>
			fetchDistributeur.data
				? fetchDistributeur.data.map((distributeur) => ({
					value: distributeur.Id_enseignes,
					label: distributeur.Nom_enseigne
				  }))
				: [],
		[fetchDistributeur]
	)

	const typeOptions: FormSelectFieldModel[] = useMemo(
		() =>
			Object.keys(typePointDeVenteEnum).map((type) => ({
				label: typePointDeVenteEnum[type as keyof typeof typePointDeVenteEnum],
				value: typePointDeVenteEnum[type as keyof typeof typePointDeVenteEnum]
			})),
		[typePointDeVenteEnum]
	)

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title={(creation ? 'Créer' : 'Modifier') + ' un point de vente'}
			backgroundColor="#5bc0de"
		>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={pointDeVente}
				render={({ handleSubmit, errors }) => (
					<form onSubmit={handleSubmit}>
						<Grid sx={{ p: '20px 24px' }} container item xs={12}>
							<Field
								name="Nom_du_PDV"
								type="text"
								label="Nom du point de vente"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							{(session.role === sessionEnum.superAdministrateur ||
								session.role === sessionEnum.responsablePointDeVente) && (
								<Field
									name="Id_enseignes"
									label="Sélectionner un distributeur"
									component={FormSelectField}
									options={categoryOptions}
									sx={{
										marginBottom: '10px'
									}}
								/>
							)}
							<Field
								name="Type"
								label="Sélectionner un type"
								component={FormSelectField}
								options={typeOptions}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name="Description_du_PDV"
								type="text"
								label="Donnez une description du point de vente"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
								multiline
								rows={5}
							/>
							<Field
								name="Identifiant_technique_transmitterid"
								type="text"
								label="Identifiant technique"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
						</Grid>
						<Grid
							sx={{
								p: '15px',
								bgcolor: 'backgrounds.blue'
							}}
							container
							item
							xs={12}
							justifyContent="center"
						>
							<Grid container item xs={6} justifyContent="left">
								<Button
									bgcolor="buttonRed"
									color="white"
									type={buttonTypesEnum.reset}
									onClick={onCloseDialog}
								>
									ANNULER
								</Button>
							</Grid>
							<Grid container item xs={6} justifyContent="right">
								<Button
									bgcolor="green"
									color="white"
									type={buttonTypesEnum.submit}
									disabled={disabled}
									opacity={disabled ? 0.5 : 1}
								>
									VALIDER
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			/>
		</Dialog>
	)
}

export default PopinPointDeVente
