import { Box, Grid, Link } from '@mui/material'
import React, { useContext } from 'react'
import { AppContext } from '../../containers/context/AppContext'
import {
	Apartment,
	CalendarMonth,
	Check,
	DoNotDisturb,
	People,
	QuestionMark,
	ShoppingCart
} from '@mui/icons-material'
import { sessionEnum } from '../../containers/enums/sessionEnum'
import { linksEnum, linksEnumData } from '../../containers/enums/linksEnum'
import { LinkModel } from './types/linksModel'

const MenuLeft = () => {
	const { session } = useContext(AppContext)

	const getLinkIcon = (value: linksEnum) => {
		switch (value) {
			case linksEnum.enseignes:
				return <Apartment />
			case linksEnum.pointsDeVentes:
				return <ShoppingCart />
			case linksEnum.utilisateurs:
				return <People />
			case linksEnum.saisie:
				return <Check />
			case linksEnum.historique:
				return <CalendarMonth />
			case linksEnum.faq:
				return <QuestionMark />
			default:
				return <DoNotDisturb />
		}
	}

	const links: LinkModel[] = []
	switch (session.role) {
		case sessionEnum.superAdministrateur:
			links.push(
				linksEnumData.enseignes,
				linksEnumData.pointsDeVentes,
				linksEnumData.utilisateurs,
				linksEnumData.historique,
				linksEnumData.faq
			)
			break
		case sessionEnum.valideur:
			links.push(linksEnumData.historique, linksEnumData.faq)
			break
		case sessionEnum.agentDePointDeVente:
			links.push(linksEnumData.saisie, linksEnumData.historique, linksEnumData.faq)
			break
		case sessionEnum.responsableReseau:
			links.push(
				linksEnumData.pointsDeVentes,
				linksEnumData.utilisateurs,
				linksEnumData.historique,
				linksEnumData.faq
			)
			break
		case sessionEnum.responsablePointDeVente:
			links.push(linksEnumData.pointsDeVentes, linksEnumData.faq)
			break
	}

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				backgroundColor: 'backgrounds.darkPurple',
				alignContent: 'flex-start',
				flexDirection: 'column'
			}}
		>
			{links.map((link, key) => {
				return (
					<Link
						underline="none"
						href={link.url}
						sx={{
							color: 'texts.button',
							display: 'flex',
							alignItems: 'center',
							fontWeight: 700,
							position: 'relative',
							padding: '12px 0',
							width: '100%',
							transition: 'all .2s linear',
							height: 'fit-content',
							'&:hover': {
								backgroundColor: 'backgrounds.darkGrey',
								color: 'texts.white'
							},
							'& > svg': {
								marginLeft: '15px'
							}
						}}
						key={link.label}
					>
						{getLinkIcon(link.url)}
						<Box
							component="span"
							sx={{
								marginLeft: 1
							}}
						>
							{link.label}
						</Box>
					</Link>
				)
			})}
		</Grid>
	)
}
export default MenuLeft
