export enum lotEnum {
	Numero_logistique_premiere_carte = 'Numero_logistique_premiere_carte',
	Numero_logistique_derniere_carte = 'Numero_logistique_derniere_carte',
	Montant_par_carte = 'Montant_par_carte'
}

export enum formLotsEnum {
	pdv = 'pdv',
	lots = 'lots',
	commentaire = 'commentaire'
}
