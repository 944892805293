import React from 'react'
import { Link as MuiLink } from '@mui/material'
import { LinkType } from './types/linkModel'

const Link: React.FC<LinkType> = ({
	children,
	target = '_self',
	href,
	color = 'button',
	bold,
	hover,
	onClick
}) => {
	const linkOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (onClick) {
			event.preventDefault()
			onClick()
		}
	}

	return (
		<MuiLink
			href={href}
			underline={hover ? 'hover' : 'none'}
			target={target}
			sx={{
				color: `texts.${color}`,
				fontWeight: `${bold ? 'bold' : 'normal'}`
			}}
			onClick={linkOnClick}
		>
			{children}
		</MuiLink>
	)
}

export default Link
