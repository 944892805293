import { createTheme } from '@mui/material'

// Important lors de l'utilisation de marge et de padding :
// L'espacement du theme est de 8 par défaut (et n'a pas été modifié)

const backgroundDarkBlue = '#3f4e62'
const backgroundBlue = '#495a72'
const backgroundLightBlue = '#3092b0'
const backgroundButtonBlue = '#418bca'
const backgroundOrange = '#f89406'
const backgroundLightOrange = '#fef9f3'
const backgroundWhite = '#FFFFFF'
const backgroundDarkGrey = '#2b3543'
const backgroundGrey = '#354252'
const backgroundMidGrey = '#dbe0e2'
const backgroundLightLightGrey = 'rgba(0, 0, 0, 0.03)'
const backgroundDarkGreen = '#519e52'
const backgroundGreen = '#16a085'
const backgroundDarkPurple = '#493d55'
const backgroundPurple = '#51445f'
const backgroundLightPurple = '#8f81a2'
const backgroundButtonPurple = '#422a5a'
const backgroundPink = '#f9dde1'
const backgroundButtonYellow = '#ffc100'
const backgroundButtonRed = '#e05d6f'
const backgroundButtonRedContainer = '#eba5a3'
const backgroundLightYellow = '#fcf8e3'
const backgroundSuccess = '#eaf6ea'

const textGrey = '#616f77'
const textSlateGrey = '#3f4e62'
const textOrange = '#f0ad4e'
const textGreen = '#16a085'
const textLightRed = '#e05d6f'
const textButton = 'rgba(255, 255, 255, .7)'
const textSelectedTableColumn = '#428bca'
const textHoverTableColumn = '#6aa3d5'
const textSuccess = '#5cb85c'
const textWhite = '#FFFFFF'
const textBlue = '#418bca'

export const theme = createTheme({
	palette: {
		backgrounds: {
			darkBlue: backgroundDarkBlue,
			blue: backgroundBlue,
			lightBlue: backgroundLightBlue,
			buttonBlue: backgroundButtonBlue,
			orange: backgroundOrange,
			lightOrange: backgroundLightOrange,
			white: backgroundWhite,
			darkGrey: backgroundDarkGrey,
			grey: backgroundGrey,
			midGrey: backgroundMidGrey,
			lightlightGrey: backgroundLightLightGrey,
			darkGreen: backgroundDarkGreen,
			green: backgroundGreen,
			darkPurple: backgroundDarkPurple,
			purple: backgroundPurple,
			lightPurple: backgroundLightPurple,
			buttonPurple: backgroundButtonPurple,
			pink: backgroundPink,
			buttonYellow: backgroundButtonYellow,
			buttonRed: backgroundButtonRed,
			lightYellow: backgroundLightYellow,
			lightBackgroundSuccess: backgroundSuccess,
			buttonRedContainer: backgroundButtonRedContainer
		},
		texts: {
			grey: textGrey,
			slateGrey: textSlateGrey,
			orange: textOrange,
			green: textGreen,
			white: textWhite,
			lightRed: textLightRed,
			button: textButton,
			selectedTableColumn: textSelectedTableColumn,
			hoverTableColumn: textHoverTableColumn,
			blue: textBlue
		},
		success: {
			main: textSuccess
		}
	},
	typography: {
		fontFamily: ['Lato', 'Arial', 'sans-serif'].join(',')
	}
})
