export enum pointDeVenteTableLabelEnum {
	nom = 'Nom',
	distributeur = 'Distributeur',
	type = 'Type',
	identifiantTechnique = 'Identifiant technique',
	nombreDeDemandes = 'Nombre de demandes',
	modalsButtons = ''
}

export const getKeyFromPointDeVenteTableLabelValue = (
	value: pointDeVenteTableLabelEnum
) =>
	Object.keys(pointDeVenteTableLabelEnum).find(
		(pointDeVenteTableLabel) =>
			pointDeVenteTableLabelEnum[
				pointDeVenteTableLabel as keyof typeof pointDeVenteTableLabelEnum
			] === value
	) ?? ''
