import Dialog from '../../../components/dialog/Dialog'
import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { EnseigneModel } from '../types/enseigneModel'
import { AppContext } from '../../../containers/context/AppContext'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import { statusDistributeur } from '../api/enseignesApi'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	enseigne: EnseigneModel
	activation: boolean
}

type PopinDesactivationProps = AcceptingProps

const PopinDesactivation: React.FC<PopinDesactivationProps> = ({
	onCloseDialog,
	open,
	enseigne,
	activation
}) => {
	const { setSnackBar } = useContext(AppContext)

	const onSubmit = () => {
		statusDistributeur(enseigne.Id_enseignes, activation).then((response: any) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'distributeur',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'distributeur',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
			}
			onCloseDialog()
		})
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title={activation ? 'Réactiver un distributeur' : 'Désactiver un distributeur'}
			backgroundColor={activation ? '#5cb85c' : '#d9534f'}
		>
			<>
				<Grid sx={{ p: '20px 24px' }} container item xs={12}>
					Voulez-vous vraiment {activation ? 'réactiver' : 'désactiver'} le
					distributeur&nbsp;&nbsp;<b>{enseigne.Nom_enseigne}</b> ?
				</Grid>
				<Grid
					sx={{
						p: '15px',
						bgcolor: 'backgrounds.blue'
					}}
					container
					item
					xs={12}
					justifyContent="center"
				>
					<Grid container item xs={6} justifyContent="left">
						<Button
							bgcolor="buttonRed"
							color="white"
							type={buttonTypesEnum.reset}
							onClick={onCloseDialog}
						>
							ANNULER
						</Button>
					</Grid>
					<Grid container item xs={6} justifyContent="right">
						<Button
							bgcolor="green"
							color="white"
							type={buttonTypesEnum.submit}
							onClick={onSubmit}
						>
							{activation ? 'RÉACTIVER' : 'DÉSACTIVER'}
						</Button>
					</Grid>
				</Grid>
			</>
		</Dialog>
	)
}

export default PopinDesactivation
