import axios from 'axios'
import { submitModel } from '../../../types/logInModel'
import { submitModel as submitModelRegistration } from '../../../types/registrationModel'

export const postConnexion = (data: submitModel) =>
	axios.post('/api/connexion', data).then((response) => response.data)

export const postFirstConnection = (data: submitModelRegistration) =>
	axios.post('/api/premiere-connexion', data).then((response) => response.data)

export const postForgotPassword = (data: submitModelRegistration) =>
	axios.post('/api/mot-de-passe-oublie', data).then((response) => response.data)
