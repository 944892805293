export enum faqTableHeadEnum {
	question = 'Question',
	answer = 'Réponse',
	category = 'Catégorie',
	position = 'Ordre'
}

export const getKeyFromFaqTableHead = (value: faqTableHeadEnum) =>
	Object.keys(faqTableHeadEnum).find(
		(faqTableHead) =>
			faqTableHeadEnum[faqTableHead as keyof typeof faqTableHeadEnum] === value
	) ?? ''
