import React, { useContext, useState } from 'react'
import Dialog from '../../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../../components/dialog/types/dialogModel'
import { FAQModel } from '../../types/FAQModel'
import { putQuestion } from '../../api/FAQAdminAPI'
import FAQFormComponent from './FAQFormComponent'
import { popinFAQFormFieldEnum } from '../../enums/popinAddQuestionEnum'
import { FAQContext } from '../../context/FAQContext'
import {
	getKeyFromSessionValue,
	sessionEnum
} from '../../../../containers/enums/sessionEnum'

interface AcceptingProps {
	question: FAQModel
}

type PopinEditQuestionProps = AcceptingProps & DialogCommonProps

const PopinEditQuestion: React.FC<PopinEditQuestionProps> = ({
	onCloseDialog,
	open,
	question
}) => {
	const [loading, setLoading] = useState(false)
	const { categories } = useContext(FAQContext)

	const onSubmit = (values: FAQModel) => {
		setLoading(true)

		return putQuestion(values, question[popinFAQFormFieldEnum.id])
			.then((response) => onCloseDialog())
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.messages) {
					const errorData = error.response.data.messages

					if (typeof errorData === 'object') {
						return Object.values(errorData)
							.map((errorValue) => `${errorValue} \n`)
							.join('')
					}
				}
			})
			.finally(() => setLoading(false))
	}

	const getCategoryFromLabel = () =>
		categories.filter(
			(category) => category.label === question[popinFAQFormFieldEnum.category]
		)

	return (
		<Dialog onCloseDialog={onCloseDialog} open={open} title="Modifier la question">
			<FAQFormComponent
				onSubmit={onSubmit}
				loading={loading}
				onCloseDialog={onCloseDialog}
				initialValues={
					categories.length > 0 && getCategoryFromLabel().length > 0
						? {
							...question,
							[popinFAQFormFieldEnum.category]: getCategoryFromLabel()[0].id,
							[popinFAQFormFieldEnum.relatedRole]: getKeyFromSessionValue(
								question[popinFAQFormFieldEnum.relatedRole]
							) as sessionEnum
						  }
						: undefined
				}
			/>
		</Dialog>
	)
}

export default PopinEditQuestion
