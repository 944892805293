import { LinkModel } from '../../modules/menu/types/linksModel'

export enum linksEnum {
	login = '/login',
	enseignes = '/distributeurs',
	pointsDeVentes = '/points-de-ventes',
	historique = '/historique',
	saisie = '/saisie',
	indisponible = '/indisponible',
	changerDeMotDePasse = '/changer-de-mot-de-passe',
	forgotpass = '/mot-de-pass-oublie',
	reinitpass = '/reinitialiser-mot-de-passe/:id/:token',
	utilisateurs = '/utilisateurs',
	faq = '/faq',
	rechercheUnitaire = '/recherche-unitaire'
}

export const linksEnumData: { [key: string]: LinkModel } = {
	enseignes: {
		url: linksEnum.enseignes,
		label: 'Gestion des distributeurs'
	},
	pointsDeVentes: {
		url: linksEnum.pointsDeVentes,
		label: 'Gestion des points de vente'
	},
	utilisateurs: {
		url: linksEnum.utilisateurs,
		label: 'Gestion des utilisateurs'
	},
	saisie: {
		url: linksEnum.saisie,
		label: 'Activer des cartes en lot'
	},
	historique: {
		url: linksEnum.historique,
		label: 'Historique des activations'
	},
	faq: {
		url: linksEnum.faq,
		label: 'Une question'
	},
	rechercheUnitaire: {
		url: linksEnum.rechercheUnitaire,
		label: 'Recherche unitaire par carte'
	}
}
