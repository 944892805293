import React, { ChangeEvent, useContext } from 'react'
import {
	Box,
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel
} from '@mui/material'
import { ObjectModel, TableType } from './types/tableModel'
import { orderEnum } from './enums/orderEnum'
import { AppContext } from '../../containers/context/AppContext'
import TableSearchBar from './components/TableSearchBar'
import { getKeyFromEnumValue } from '../../utils/utils'
import { columnsEnum } from './enums/columnsEnum'
import { historiqueActivationTableLabelEnum } from '../../modules/historique/enums/historiqueActivationEnum'

const CustomTable: React.FC<TableType> = ({
	title,
	headRows,
	rows,
	buttonCreateLabel,
	buttonCreateOnClick,
	defaultOrderBy,
	defaultOrder,
	defaultRowsPerPage,
	tableContainerStyle,
	hasPagination = true,
	rowBackgroundColor,
	componentBeforeTable,
	tableStyle,
	referenceColumn
}) => {
	const { configurations } = useContext(AppContext)
	const [order, setOrder] = React.useState<orderEnum>(defaultOrder ?? orderEnum.asc)
	const [orderBy, setOrderBy] = React.useState<string | undefined>(
		defaultOrderBy ?? undefined
	)
	const [filter, setFilter] = React.useState<{ [key: string]: string }>({})
	const rowsElements: ObjectModel[] = React.useMemo(
		() =>
			Object.keys(filter).reduce(
				(acc, filterKey) =>
					acc.filter((row) =>
						row[filterKey as keyof typeof row]
							.toString()
							.toLowerCase()
							.includes(filter[filterKey as keyof typeof filter].toLowerCase())
					),
				rows
			),
		[filter, rows]
	)

	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage ?? 10)
	const searchBarIdsToHide = React.useMemo(
		() => [
			getKeyFromEnumValue<columnsEnum>(columnsEnum, columnsEnum.modalsButtons),
			getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
				historiqueActivationTableLabelEnum,
				historiqueActivationTableLabelEnum.comment
			),
			getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
				historiqueActivationTableLabelEnum,
				historiqueActivationTableLabelEnum.pdf
			),
			getKeyFromEnumValue<historiqueActivationTableLabelEnum>(
				historiqueActivationTableLabelEnum,
				historiqueActivationTableLabelEnum.details
			)
		],
		[]
	)

	const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
		const isAsc = orderBy === property && order === orderEnum.asc
		setOrder(isAsc ? orderEnum.desc : orderEnum.asc)
		setOrderBy(property)
	}

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	// On enregistre sur quelle colonne on a édité le filtre
	const onInputChanged = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		id: string
	) => {
		setFilter((prevFilter) => ({
			...prevFilter,
			[id]: event.target.value
		}))
	}

	// Tri par colonne
	const rowsDataOrdered = rowsElements.sort((a: object, b: object) => {
		if (a[orderBy as keyof typeof a] < b[orderBy as keyof typeof b]) {
			return order === orderEnum.asc ? -1 : 1
		}
		if (a[orderBy as keyof typeof a] > b[orderBy as keyof typeof b]) {
			return order === orderEnum.asc ? 1 : -1
		}
		return 0
	})

	// Pagination.
	const rowsData =
		rowsPerPage !== -1
			? rowsDataOrdered.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
			: rowsElements

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				backgroundColor: 'backgrounds.white',
				marginTop: '30px',
				...tableContainerStyle
			}}
		>
			{title && (
				<Box
					component="div"
					sx={{
						borderBottom: 'solid 1px rgba(0, 0, 0, .1)',
						width: '100%'
					}}
				>
					<Box
						component="h1"
						sx={{
							padding: '8px 15px',
							margin: 0,
							display: 'inline-block',
							fontWeight: 400,
							fontSize: '20px',
							lineHeight: '26px'
						}}
					>
						{`${title} (${rowsElements.length})`}
					</Box>
				</Box>
			)}
			<TableContainer
				sx={{
					padding: '15px'
				}}
			>
				{componentBeforeTable && componentBeforeTable}

				{buttonCreateLabel && (
					<Button className="button-table-create" onClick={buttonCreateOnClick}>
						{buttonCreateLabel}
					</Button>
				)}

				<Table sx={{ minWidth: 700, ...tableStyle }}>
					{headRows && (
						<TableHead>
							<TableRow>
								{headRows.map((headRow) => (
									<TableCell
										key={headRow.id}
										sortDirection={orderBy === headRow.id ? order : false}
										sx={
											orderBy === headRow.id
												? {
													textAlign: 'center',
													borderBottom: '3px solid',
													borderBottomColor: 'texts.selectedTableColumn'
												  }
												: {
													textAlign: 'center'
												  }
										}
									>
										<TableSortLabel
											active={orderBy === headRow.id}
											direction={orderBy === headRow.id ? order : orderEnum.asc}
											onClick={createSortHandler(headRow.id)}
										>
											<strong>{headRow.label}</strong>
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>

							<TableRow>
								{headRows.map((headRow) => (
									<TableCell
										key={headRow.id}
										sortDirection={orderBy === headRow.id ? order : false}
										sx={
											orderBy === headRow.id
												? {
													textAlign: 'center',
													borderBottom: '3px solid',
													borderBottomColor: 'texts.selectedTableColumn'
												  }
												: {
													textAlign: 'center'
												  }
										}
									>
										{
											// On masque la recherche pour la colonne des boutons
											!searchBarIdsToHide.includes(headRow.id) && (
												<TableSearchBar
													id={headRow.id}
													name={headRow.id}
													onChange={(e) => onInputChanged(e, headRow.id)}
													value={filter[headRow.id as keyof typeof filter]}
													placeholder={`Recherche par ${headRow.label.toLowerCase()}`}
												/>
											)
										}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{rowsData.map((row, index) => (
							<TableRow
								key={`${title}-table-row-${page}-${index}`}
								sx={{
									backgroundColor: rowBackgroundColor
								}}
							>
								{Object.values(row).map((value: string, indexValue) => (
									<TableCell
										key={`table-row-${value}-${page}-${indexValue}`}
										sx={{
											textAlign: 'center',
											padding: '10px'
										}}
									>
										{indexValue === referenceColumn ? (
											<img src={`${configurations.links.socUrl}` + '/api/image/' + `${value}`} />
										) : (
											value
										)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
					{hasPagination && rowsData.length > 0 && (
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, { label: 'Tout', value: -1 }]}
									colSpan={Object.keys(rowsElements[0]).length}
									count={rowsElements.length}
									rowsPerPage={rowsPerPage}
									page={page}
									sx={{
										borderBottom: 'none'
									}}
									SelectProps={{
										inputProps: {
											'aria-label': 'Lignes par page'
										},
										native: true
									}}
									labelRowsPerPage="Lignes par page :"
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</TableRow>
						</TableFooter>
					)}
				</Table>
			</TableContainer>
		</Grid>
	)
}

export default CustomTable
