export interface UtilisateurModel {
	Active: boolean
	Email: string
	Enseigne_active: boolean | null
	Id_user: string
	Nom: string
	Nom_du_PDV: string | null
	Nom_enseigne: string | null
	Nom_user: string
	Pdv_active: boolean | null
	Prenom_user: string
	nombreConnexionEchoue: string
	Id_points_de_ventes?: number[]
	Id_enseignes?: number[]
}

export const INITIAL_UTILISATEUR: UtilisateurModel = {
	Active: true,
	Email: '',
	Enseigne_active: true,
	Id_user: '',
	Nom: '',
	Nom_du_PDV: '',
	Nom_enseigne: '',
	Nom_user: '',
	Pdv_active: true,
	Prenom_user: '',
	nombreConnexionEchoue: ''
}
