export enum sessionEnum {
	disconnected = 'non authentifie',
	superAdministrateur = 'super administrateur',
	valideur = 'valideur',
	agentDePointDeVente = 'agent de point de vente',
	responsableReseau = 'responsable reseau',
	responsablePointDeVente = 'responsable point de vente'
}

export const getKeyFromSessionValue = (value: sessionEnum) =>
	Object.keys(sessionEnum).find(
		(sessionValue) => sessionEnum[sessionValue as keyof typeof sessionEnum] === value
	) ?? ''
