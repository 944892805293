import React, { useMemo } from 'react'
import { Grid } from '@mui/material'
import Dialog from '../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import { ActivationRefCartesModel } from '../types/activationModel'
import {
	detailsActivationTableHeadEnum,
	getKeyFromDetailsActivationsTableHead
} from '../enums/detailsActivationEnum'
import CustomTable from '../../../components/table/Table'

interface AcceptingProps {
	refCartes: ActivationRefCartesModel[]
}

type PopinDetailsActivationProps = AcceptingProps & DialogCommonProps

const PopinDetailsActivation: React.FC<PopinDetailsActivationProps> = ({
	onCloseDialog,
	open,
	refCartes
}) => {
	const detailsActivationTableHeads = useMemo(
		() => [
			{
				label: detailsActivationTableHeadEnum.numeroLogistique,
				id: getKeyFromDetailsActivationsTableHead(
					detailsActivationTableHeadEnum.numeroLogistique
				)
			},
			{
				label: detailsActivationTableHeadEnum.montant,
				id: getKeyFromDetailsActivationsTableHead(detailsActivationTableHeadEnum.montant)
			},
			{
				label: detailsActivationTableHeadEnum.etatDeLaCarte,
				id: getKeyFromDetailsActivationsTableHead(
					detailsActivationTableHeadEnum.etatDeLaCarte
				)
			},
			{
				label: detailsActivationTableHeadEnum.raisonAnomalie,
				id: getKeyFromDetailsActivationsTableHead(
					detailsActivationTableHeadEnum.raisonAnomalie
				)
			}
		],
		[]
	)

	return (
		<Dialog onCloseDialog={onCloseDialog} open={open} maxWidth="md">
			<Grid sx={{ p: '20px 24px' }} container item xs={12}>
				<CustomTable
					title="Détail de la demande"
					headRows={detailsActivationTableHeads}
					rows={refCartes.map((refCarte) =>
						detailsActivationTableHeads.reduce(
							(acc, detailsActivationTableHead) => ({
								...acc,
								[detailsActivationTableHead.id]:
									refCarte[detailsActivationTableHead.id as keyof typeof refCarte]
							}),
							{}
						)
					)}
					tableContainerStyle={{ mt: 0 }}
				/>
			</Grid>
		</Dialog>
	)
}

export default PopinDetailsActivation
