export enum historiqueActivationTableLabelEnum {
	reference = 'Référence',
	date = 'Date',
	agent = 'Agent',
	pointDeVente = 'Point de vente',
	statut = 'Statut',
	motif = 'Motif',
	montant = 'Montant',
	comment = 'Commentaire',
	pdf = 'PDF',
	details = 'Détail'
}

export const getKeyFromHistoriqueActivationTableLabelValue = (
	value: historiqueActivationTableLabelEnum
) =>
	Object.keys(historiqueActivationTableLabelEnum).find(
		(historiqueActivationTableLabel) =>
			historiqueActivationTableLabelEnum[
				historiqueActivationTableLabel as keyof typeof historiqueActivationTableLabelEnum
			] === value
	) ?? ''
