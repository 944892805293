import axios from 'axios'
import { ActivationFormModel, ActivationModel } from '../types/activationModel'

export const fetchHistoriqueActivations = (
	targetPDV: string
): Promise<ActivationModel[]> => {
	return axios(`/api/demandes?pdv=${targetPDV}`)
		.then((res) => res.data)
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data)
				console.log(error.response.status)
				console.log(error.response.headers)
			} else if (error.request) {
				console.log(error.request)
			} else {
				console.log('Error', error.message)
			}
			console.log(error.config)
		})
}

export const cancelActivation = (id: string, data: ActivationFormModel) =>
	axios.put(`/api/demandes/${id}/annuler`, data)
