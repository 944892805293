import Dialog from '../../../components/dialog/Dialog'
import React, { useContext, useState } from 'react'
import FormTextField from '../../../components/fields/FormTextField'
import { Field, Form } from 'react-final-form'
import { DropzoneArea } from 'material-ui-dropzone'
import { Box, Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { EnseigneModel } from '../types/enseigneModel'
import { AppContext } from '../../../containers/context/AppContext'
import { enseignesFieldsEnum } from '../enums/enseignesEnum'
import FormDropzoneAreaField from '../../../components/fields/FormDropzoneAreaField'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import { addDistributeur } from '../api/enseignesApi'

interface AcceptingProps {
	enseigne: EnseigneModel
}

type PopinDistributeurProps = AcceptingProps & DialogCommonProps

const PopinDistributeur: React.FC<PopinDistributeurProps> = ({
	onCloseDialog,
	open,
	enseigne
}) => {
	const [disabled, setDisabled] = useState<boolean>(true)
	const [file, setFile] = useState<File[]>([])

	const { setSnackBar } = useContext(AppContext)

	const validate = (values: EnseigneModel) => {
		const errors: any = {}
		if (!values.Nom_enseigne) {
			errors.Nom_enseigne = 'Le nom de l\'enseigne est obligatoire'
		}
		if (!values.Ezp_id) {
			errors.Ezp_id = 'L\'identifiant Ezp de l\'enseigne est obligatoire'
		}
		if (!values.Ezp_mdp) {
			errors.Ezp_mdp = 'Le mot de passe Ezp de l\'enseigne est obligatoire'
		}
		if (
			!values.Nombre_saisie ||
			Math.round(values.Nombre_saisie) != values.Nombre_saisie ||
			values.Nombre_saisie > 5 ||
			values.Nombre_saisie < 1
		) {
			errors.Nombre_saisie = 'Veuillez entrer un chiffre entre 1 et 5'
		}
		let min = 0
		let max = 0
		if (
			!values.Montant_min ||
			Math.round(values.Montant_min) != values.Montant_min ||
			values.Montant_min < 1
		) {
			errors.Montant_min = 'Veuillez entrer un nombre entier'
		} else {
			min = values.Montant_min
		}
		if (
			!values.Montant_max ||
			Math.round(values.Montant_max) != values.Montant_max ||
			values.Montant_max > 250
		) {
			errors.Montant_max = 'Veuillez entrer un nombre entier'
		} else {
			max = values.Montant_max
		}
		if (min > max) {
			errors.Montant_max =
				'Veuillez entrer un chiffre entier supérieur au montant minimal de chargement'
		}
		if (
			!values.Nombre_max_cartes ||
			Math.round(values.Nombre_max_cartes) != values.Nombre_max_cartes ||
			values.Nombre_max_cartes < 1
		) {
			errors.Nombre_max_cartes = 'Veuillez entrer un nombre entier'
		}
		setDisabled(Object.keys(errors).length > 0)
		return errors
	}

	const onSubmit = (values: EnseigneModel) => {
		const formData = new FormData()

		// On ajoute le logo au formData de la requête s'il y en a un
		if (file.length > 0) {
			formData.append('file', file[0], file[0].name)
		}

		// De même pour le fichier de réapprovisionnement de stock
		if (values.reapprovisionnement && values.reapprovisionnement.length > 0) {
			formData.append(
				enseignesFieldsEnum.reapprovisionnement,
				values.reapprovisionnement[0],
				values.reapprovisionnement[0].name
			)
		}
		// Suppression de la clé "reapprovisionnement" dans les values pour ne pas l'envoyer avec les values
		delete values.reapprovisionnement

		formData.append('enseigne', JSON.stringify(values))
		let url = '/api/distributeurs'
		if (values.Id_enseignes != '') {
			url += '/' + values.Id_enseignes
		}

		addDistributeur(url, formData).then((response: any) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'distributeur',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'distributeur',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				onCloseDialog()
			}
		})
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title="Créer un distributeur"
			backgroundColor="#5bc0de"
		>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={enseigne}
				render={({ handleSubmit, form }) => (
					<form onSubmit={handleSubmit}>
						<Grid sx={{ p: '20px 24px' }} container item xs={12}>
							<Field
								name={enseignesFieldsEnum.Nom_enseigne}
								type="text"
								label="Nom du distributeur"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name={enseignesFieldsEnum.Ezp_id}
								type="text"
								label="Identifiant EZP du distibuteur"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name={enseignesFieldsEnum.Ezp_mdp}
								type="text"
								label="Mot de passe EZP du distibuteur"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name={enseignesFieldsEnum.Nombre_saisie}
								type="number"
								label="Nombre de saisies (5 maximun)"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name={enseignesFieldsEnum.Montant_min}
								type="number"
								label="Montant minimal de chargement"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name={enseignesFieldsEnum.Montant_max}
								type="number"
								label="Montant maximal de chargement"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name={enseignesFieldsEnum.Nombre_max_cartes}
								type="number"
								label="Nombre maximal de cartes par chargement"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<DropzoneArea
								onChange={(files) => setFile(files)}
								dropzoneText="Faites glisser un fichier image ici (200 kb maximum)"
								maxFileSize={200000}
								inputProps={{
									name: enseignesFieldsEnum.file
								}}
								filesLimit={1}
							/>

							{/* Bloc d'insertion du fichier de réapprovisionnement */}
							<Box
								sx={{
									marginTop: '10px',
									width: '100%'
								}}
							>
								<Field
									name={enseignesFieldsEnum.reapprovisionnement}
									label={
										'Faites glisser un fichier de réapprovisionnement "stock" ici (2 mb maximum)'
									}
									component={FormDropzoneAreaField}
									fileSize={2000000}
									accept={[
										'.csv',
										'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
										'application/vnd.ms-excel'
									]}
								/>
							</Box>
						</Grid>
						<Grid
							sx={{
								p: '15px',
								bgcolor: 'backgrounds.blue'
							}}
							container
							item
							xs={12}
							justifyContent="center"
						>
							<Grid container item xs={6} justifyContent="left">
								<Button
									bgcolor="buttonRed"
									color="white"
									type={buttonTypesEnum.reset}
									onClick={onCloseDialog}
								>
									ANNULER
								</Button>
							</Grid>
							<Grid container item xs={6} justifyContent="right">
								<Button
									bgcolor="green"
									color="white"
									type={buttonTypesEnum.submit}
									disabled={disabled}
									opacity={disabled ? 0.5 : 1}
								>
									VALIDER
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			/>
		</Dialog>
	)
}

export default PopinDistributeur
