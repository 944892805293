import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Box, Grid, Input } from '@mui/material'
import { FormApi } from 'final-form'
import { Info } from '@mui/icons-material'

interface AcceptingProps {
	id: string
	label?: string
	placeholder?: string
	multiline?: boolean
	maxLength?: number
	type?: string
	disablePaste: boolean
	adornment?: string
	inputOnChange?: (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void
	inputOnKeyUp?: (
		event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void
	inputOnPaste?: () => void
	showPassword?: boolean
	seePassword?: boolean
	handleClickShowPassword?: () => void
	fontSize?: string
	maxRow?: string
	minRow?: string
	hasPopIn?: boolean
	popInOnClick?: () => void
}

type FormTextFieldProps = FieldRenderProps<string> & AcceptingProps

const FormBoxTextField: React.FC<FormTextFieldProps> = ({
	input,
	meta: { touched, error },
	id,
	label,
	placeholder,
	multiline,
	maxLength,
	type = 'text',
	disablePaste = false,
	adornment,
	inputOnChange,
	inputOnPaste,
	inputOnKeyUp,
	fontSize = '14px',
	rows,
	maxRows,
	minRows,
	hasPopIn = false,
	popInOnClick
}) => {
	const handleMouseDownPassword = (event: any) => {
		event.preventDefault()
	}

	return (
		<>
			{label && (
				<Grid container alignItems="center">
					<span
						style={{
							display: 'block',
							paddingTop: 4,
							fontSize: `${fontSize}`,
							marginBottom: '5px',
							marginLeft: 0,
							boxSizing: 'border-box',
							textAlign: 'left'
						}}
					>
						{label}
					</span>
					{hasPopIn && (
						<Info
							onClick={popInOnClick}
							sx={{
								color: '#22beef',
								cursor: 'pointer'
							}}
						/>
					)}
				</Grid>
			)}
			<Input
				id={id}
				color="primary"
				multiline={multiline}
				rows={rows}
				maxRows={maxRows}
				minRows={minRows}
				inputProps={{
					maxLength: maxLength,
					onPaste: inputOnPaste,
					onChange: inputOnChange,
					onKeyUp: inputOnKeyUp
				}}
				sx={{
					width: '100%',
					color: 'texts.grey',
					outline: 0,
					verticalAlign: 'top',
					backgroundColor: 'backgrounds.white',
					filter: 'none',
					boxShadow: 'none',
					'& > input, & > textarea': {
						padding: '6px 12px',
						border: '1px solid',
						borderRadius: '2px',
						borderColor: 'backgrounds.midGrey',
						transition: 'all .2s linear'
					},
					'& > textarea::placeholder': {
						fontSize: '14px',
						fontStyle: 'italic'
					},
					':before': {
						content: 'none'
					},
					':after': {
						content: 'none'
					},
					':hover:not(:disabled)': {
						borderColor: '#b1bac0'
					},
					'& > *:active:not(:disabled), & > *:focus:not(:disabled)': {
						borderColor: '#22beef'
					}
				}}
				placeholder={placeholder}
				onPaste={(e) => disablePaste && e.preventDefault()}
				{...input}
			/>

			{error && (
				<Box
					sx={{
						color: 'texts.lightRed',
						fontSize: '12px',
						width: '100%'
					}}
				>
					{error}
				</Box>
			)}
		</>
	)
}

export default FormBoxTextField
