import axios from 'axios'
import { UtilisateurModel } from '../types/utilisateurModel'

export const fetchUtilisateurs = (): Promise<UtilisateurModel[]> =>
	axios('/api/users')
		.then((res) => res.data)
		.catch((error) => {
			if (error.response) {
				console.log(error.response.data)
				console.log(error.response.status)
				console.log(error.response.headers)
			} else if (error.request) {
				console.log(error.request)
			} else {
				console.log('Error', error.message)
			}
			console.log(error.config)
		})

export const enableUser = (idUser: string) =>
	axios.put(`/api/users/${idUser}/reactiver`).then((response) => response.data)
export const disableUser = (idUser: string) =>
	axios.delete(`/api/users/${idUser}`).then((response) => response.data)

export const getDemandesByPDVUser = (idUser: string) =>
	axios
		.get(`/api/users/agents-de-point-de-vente/${idUser}/demandes`)
		.then((response) => response.data)
