export interface ReferenceProduitModel {
	Enseignes: string
	Ref_produit: string
	Montant_max: number
	Montant_min: number
}

export const INITIAL_REFERENCE_PRODUIT: ReferenceProduitModel = {
	Enseignes: '',
	Ref_produit: '',
	Montant_max: 0,
	Montant_min: 0
}
