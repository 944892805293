import { sessionEnum } from '../enums/sessionEnum'

export interface SessionModel {
	id: number | null
	role: sessionEnum
	nom: string
	prenom: string
	Id_enseignes: number | null
	reapprovisionnementFile?: string | null
	enseigne: string
	point_de_vente?: string
	logo?: string | null
}

export const INITIAL_SESSION: SessionModel = {
	id: null,
	role: sessionEnum.disconnected,
	nom: '',
	prenom: '',
	Id_enseignes: null,
	enseigne: '',
	point_de_vente: '',
	logo: null
}
